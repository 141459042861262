<template>
  <v-container
    class="accessory-container d-flex justify-center align-center pt-0 pt-md-16"
  >
    <v-row
      class="d-flex justify-center"
    >
      <v-col
        v-for="(accessory, index) in accessories"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <AccessoryCard
          :accessory="accessory"
          @optionChanged="optionChanged = true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import AccessoryCard from '../components/AccessoryCard.vue';

export default {
  name: 'Accessories', // step-4
  components: {
    AccessoryCard,
  },
  data() {
    return {
      optionChanged: false,
    };
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    accessories() {
      const result = [];
      Object.keys(this.$store.state.order.accessories).forEach((key) => {
        result.push(key);
      });
      return result;
    },
  },
  watch: {
    optionChanged(newValue, oldValue) {
      if (oldValue === false) {
        this.$track(this.$options.name, 'accessories-changed', null, this.selectedType, this.selectedVehicle);
      }
    },
  },
  mounted() {
    this.$track(this.$options.name, null, null, this.selectedType, this.selectedVehicle);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
};
</script>
