<template>
  <v-container
    class="pt-0 pt-md-16"
  >
    <div
      v-if="selectedType === 'DXS' || selectedType === 'DXS-2ndLife' || selectedType === 'DXC' || selectedType === 'DX2'"
      class="configurator-preview"
    >
      <!-- DXS, DXS-2ndLife, DXC, DX2  -->
      <div
        class="configurator-preview__images"
        :class="{ 'configurator-preview__images--dxc': (selectedVehicle === 'DXC') }"
      >
        <img
          v-if="selectedType === 'DXC' && selectedRoof === 2 && selectedBack !== 10 && selectedBack !== 11"
          id="image-roof-fixation"
          ref="roof-fixation"
          class="configurator-preview__image roof-fixation"
          src="img/views/DXC/DXC-r-roof-fixation.png"
          alt=""
        >

        <img
          v-if="selectedType === 'DXC'"
          id="image-roof"
          ref="roof"
          class="configurator-preview__image roof"
          :src="roofVehicleImageUrl"
          alt=""
        >

        <img
          id="image-front"
          ref="front"
          class="configurator-preview__image front"
          :src="frontVehicleImageUrl"
          alt=""
        >

        <img
          id="image-back"
          ref="back"
          class="configurator-preview__image back"
          :src="backVehicleImageUrl"
          alt=""
        >

        <img
          v-if="vehicleHasRimOption"
          id="image-rim"
          ref="rim"
          class="configurator-preview__image rim"
          :src="rimVehicleImageUrl"
          alt=""
        >

        <img
          id="image-colors"
          ref="color"
          class="configurator-preview__image all transition"
          :src="colorVehicleImageUrl"
          alt=""
        >

        <div
          v-if="(selectedVehicle === 'DXC' && selectedColor === 99)"
          class="color-ral"
          :style="`background-color:${hexColorRal};`"
        />

        <div
          v-if="(selectedVehicle === 'DXC' && selectedColor !== 99)"
          class="color-ral"
          :style="`background-color:#e2e2e1;`"
        />

        <a
          class="configurator-preview__selector configurator-preview__selector--front"
          :class="{ 'configurator-preview__selector--dxc-front': (selectedVehicle === 'DXC') }"
          @mouseenter="highlight('front')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('front', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-front')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            v-if="selectedVehicle === 'DXC'"
            src="img/select-back.svg"
            alt=""
          >
          <img
            v-else
            src="img/select-front.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--back"
          :class="{ 'configurator-preview__selector--dxc-back': (selectedVehicle === 'DXC') }"
          @mouseenter="highlight('back')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('back', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-back')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            v-if="selectedVehicle === 'DXC'"
            src="img/select-color.svg"
            alt=""
          >
          <img
            v-else
            src="img/select-back.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--color"
          :class="{ 'configurator-preview__selector--dxc-color': (selectedVehicle === 'DXC') }"
          @mouseenter="highlight('color')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('colors', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-color')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          v-if="selectedVehicle === 'DXC'"
          class="configurator-preview__selector configurator-preview__selector--roof"
          :class="{ 'configurator-preview__selector--dxc-roof': (selectedVehicle === 'DXC') }"
          @mouseenter="highlight('roof')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('roof', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-roof')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          v-if="vehicleHasRimOption"
          class="configurator-preview__selector configurator-preview__selector--rim"
          @mouseenter="highlight('rim')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('rim', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-rim')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-felgen.svg"
            alt=""
          >
        </a>
      </div>

      <h1
        class="text-center vehicle-title"
      >
        {{ $t('content.my') }} {{ vehicle.name[$i18n.locale] }}
        <span
          v-if="version"
          class="font-weight-light"
        >
          {{ version }}
        </span>
      </h1>
    </div>

    <div
      v-if="selectedVehicle === 'eRod'"
      class="configurator-preview"
    >
      <!-- eRod -->
      <div class="configurator-preview__images configurator-preview__images--erod">
        <img
          v-if="selectedFoiling !== 1"
          id="foilings-colors"
          ref="foiling"
          class="configurator-preview__image foiling"
          :style="`background-color:${selectedFoilingColorCode};`"
          alt=""
        >

        <img
          id="image-colors"
          ref="color"
          class="configurator-preview__image all transition"
          :src="colorVehicleImageUrl"
          alt=""
        >

        <img
          id="image-windshield"
          ref="windshield"
          class="configurator-preview__image windshield"
          :src="windshieldVehicleImageUrl"
          alt=""
        >

        <img
          id="image-wing"
          ref="wing"
          class="configurator-preview__image wing"
          :src="wingVehicleImageUrl"
          alt=""
        >

        <img
          id="image-sidepanel"
          ref="sidepanel"
          class="configurator-preview__image sidepanel"
          :src="sidepanelVehicleImageUrl"
          alt=""
        >

        <a
          class="configurator-preview__selector configurator-preview__selector--color configurator-preview__selector--erod-color"
          @mouseenter="highlight('color')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('colors', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-color')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--color configurator-preview__selector--erod-foiling"
          @mouseenter="highlight('foiling')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('foiling', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-foiling')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--windshield"
          @mouseenter="highlight('windshield')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('windshield', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-windshield')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--wing"
          @mouseenter="highlight('wing')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('wing', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-wing')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-front.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--sidepanel"
          @mouseenter="highlight('sidepanel')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('sidepanel', $event)"
        >
          <span class="dot" />
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-sidepanel')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <img
            src="img/select-sidepanel.svg"
            alt=""
          >
        </a>
      </div>

      <h1
        class="text-center vehicle-title"
      >
        {{ $t('content.my') }} {{ vehicle.name[$i18n.locale] }}
        <span
          v-if="version"
          class="font-weight-light"
        >
          {{ version }}
        </span>
      </h1>
    </div>

    <div
      v-if="selectedVehicle === 'PLUS' || selectedVehicle === 'PLUS_II'"
      class="configurator-preview"
    >
      <!-- PLUS, PLUS_II -->
      <div class="configurator-preview__images configurator-preview__images--PLUS">
        <!-- <img
          id="image-colors"
          ref="color"
          class="configurator-preview__image all transition"
          :src="colorVehicleImageUrl"
          alt=""
        > -->

        <img
          id="image-doors"
          ref="door"
          class="configurator-preview__image all transition"
          :src="doorVehicleImageUrl"
          alt=""
        >

        <img
          id="image-rim"
          ref="rim"
          class="configurator-preview__image rim"
          :src="rimVehicleImageUrl"
          alt=""
        >

        <a
          v-if="selectedVehicle"
          class="configurator-preview__selector configurator-preview__selector--color configurator-preview__selector--PLUS-color"
          @mouseenter="highlight('door')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('colors', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-color')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-color.svg"
            alt=""
          >
        </a>

        <a
          v-if="selectedVehicle != 'PLUS_II'"
          class="configurator-preview__selector configurator-preview__selector--door"
          @mouseenter="highlight('door')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('door', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-door')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-door.svg"
            alt=""
          >
        </a>

        <a
          class="configurator-preview__selector configurator-preview__selector--rim configurator-preview__selector--PLUS-rim"
          @mouseenter="highlight('rim')"
          @mouseleave="unhighlight()"
          @click="showConfiguratorSelector('rim', $event)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="txt"
            v-html="$t('content.change-rim')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <span class="dot" />
          <img
            src="img/select-felgen.svg"
            alt=""
          >
        </a>
      </div>

      <h1
        class="text-center vehicle-title"
      >
        {{ $t('content.my') }} {{ vehicle.name[$i18n.locale] }}
        <span
          v-if="version"
          class="font-weight-light"
        >
          {{ version }}
        </span>
      </h1>
    </div>

    <!-- colors -->
    <transition
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'colors'"
        type="colors"
        :elements="$kyburz.colors"
        :selected-element="selectedColor"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- fronts -->
    <transition
      v-if="selectedVehicle !== 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'front'"
        type="front"
        :elements="$kyburz.fronts"
        :selected-element="selectedFront"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- backs -->
    <transition
      v-if="selectedVehicle !== 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'back'"
        type="back"
        :elements="$kyburz.backs"
        :selected-element="selectedBack"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- rims -->
    <transition
      v-if="selectedVehicle !== 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'rim'"
        type="rim"
        :elements="$kyburz.rims"
        :selected-element="selectedRim"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- windshield -->
    <transition
      v-if="selectedVehicle === 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'windshield'"
        type="windshield"
        :elements="$kyburz.windshields"
        :selected-element="selectedWindshield"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- wing -->
    <transition
      v-if="selectedVehicle === 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'wing'"
        type="wing"
        :elements="$kyburz.wings"
        :selected-element="selectedWing"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- sidepanel -->
    <transition
      v-if="selectedVehicle === 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'sidepanel'"
        type="sidepanel"
        :elements="$kyburz.sidepanels"
        :selected-element="selectedSidepanel"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- foiling -->
    <transition
      v-if="selectedVehicle === 'eRod'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'foiling'"
        type="foiling"
        :elements="$kyburz.foilings"
        :selected-element="selectedFoiling"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- door -->
    <transition
      v-if="selectedVehicle === 'PLUS' || selectedVehicle === 'PLUS_II'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'door'"
        type="door"
        :elements="$kyburz.doors"
        :selected-element="selectedDoor"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>

    <!-- roof -->
    <transition
      v-if="selectedVehicle === 'DXC'"
      name="fade"
      appear
      mode="out-in"
    >
      <configurator-selector
        v-if="selectedOption == 'roof'"
        type="roof"
        :elements="$kyburz.roofs"
        :selected-element="selectedRoof"
        class="configurator-selector"
        @selectElement="selectElement"
        @closeSelector="closeSelector"
      />
    </transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import ConfiguratorSelector from '../components/ConfiguratorSelector.vue';

export default {
  name: 'Configurator', // step-3
  components: {
    ConfiguratorSelector,
  },
  data() {
    return {
      configuratorSelectors: [],
      selectedOption: null,
    };
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
      selectedColor: (state) => state.order.color,
      selectedFront: (state) => state.order.front,
      selectedRoof: (state) => state.order.roof,
      selectedBack: (state) => state.order.back,
      selectedRim: (state) => state.order.rim,
      selectedWindshield: (state) => state.order.windshield,
      selectedWing: (state) => state.order.wing,
      selectedSidepanel: (state) => state.order.sidepanel,
      selectedDoor: (state) => state.order.door,
      colorRal: (state) => state.order.colorRal,
      selectedFoiling: (state) => state.order.foiling,
    }),
    vehicle() {
      return this.$kyburz.vehicles.find((veh) => veh.id === this.selectedVehicle);
    },
    vehicleHasRimOption() {
      if (
        Object.prototype.hasOwnProperty.call(this.vehicle, 'rimImageUrl')
      ) {
        return true;
      }
      return false;
    },
    colorVehicleImageUrl() {
      if (this.selectedType === 'eRod') {
        if (this.selectedFoiling === 1) {
          return this.vehicle.colorImageUrl[this.selectedColor].nofoiling;
        }
        return this.vehicle.colorImageUrl[this.selectedColor].foiling;
      }
      return this.vehicle.colorImageUrl[this.selectedColor];
    },
    frontVehicleImageUrl() {
      return this.vehicle.frontImageUrl[this.selectedFront];
    },
    roofVehicleImageUrl() {
      return this.vehicle.roofImageUrl[this.selectedRoof];
    },
    backVehicleImageUrl() {
      return this.vehicle.backImageUrl[this.selectedBack];
    },
    rimVehicleImageUrl() {
      return this.vehicle.rimImageUrl[this.selectedRim];
    },
    windshieldVehicleImageUrl() {
      return this.vehicle.windshieldImageUrl[this.selectedWindshield];
    },
    wingVehicleImageUrl() {
      return this.vehicle.wingImageUrl[this.selectedWing];
    },
    sidepanelVehicleImageUrl() {
      return this.vehicle.sidepanelImageUrl[this.selectedSidepanel];
    },
    doorVehicleImageUrl() {
      return this.vehicle.doorImageUrl[this.selectedDoor][this.selectedColor];
    },
    hexColorRal() {
      return this.$kyburz.ralColors[this.colorRal];
    },
    selectedFoilingColorCode() {
      const foiling = this.$kyburz.foilings.find((foil) => foil.id === this.selectedFoiling);
      if (foiling) {
        return foiling.colorCode;
      }
      return '#FFFFFF';
    },
    version() {
      if (this.vehicle.versions) {
        const versionId = this.$store.state.order.version;
        if (versionId) {
          const versionName = this.vehicle.versions.find((version) => {
            if (version.id === versionId) {
              return true;
            }
            return false;
          });
          return versionName.name[this.$i18n.locale];
        }
      }
      return null;
    },
  },
  watch: {
    selectedOption(value) {
      if (value !== null) {
        document.addEventListener('keydown', this.escapeClick);
      } else {
        document.removeEventListener('keydown', this.escapeClick);
      }
    },
  },
  mounted() {
    this.configuratorSelectors = document.getElementsByClassName('configurator-selector');
    this.$track(this.$options.name, null, null, this.selectedType, this.selectedVehicle);
    this.$ga(this.$options.name, this.$router.currentRoute.path);

    this.fadeInFoiling();
  },
  methods: {
    highlight(option) {
      if (this.selectedType !== 'eRod') {
        if (this.$refs.front) {
          this.$refs.front.classList.add('blur');
        }
        if (this.$refs.back) {
          this.$refs.back.classList.add('blur');
        }
        if (this.$refs.color) {
          this.$refs.color.classList.add('blur');
        }
        if (this.$refs.rim) {
          this.$refs.rim.classList.add('blur');
        }
        if (this.$refs.windshield) {
          this.$refs.windshield.classList.add('blur');
        }
        if (this.$refs.wing) {
          this.$refs.wing.classList.add('blur');
        }
        if (this.$refs.sidepanel) {
          this.$refs.sidepanel.classList.add('blur');
        }
        if (this.$refs.door) {
          this.$refs.door.classList.add('blur');
        }
        if (this.$refs.roof) {
          this.$refs.roof.classList.add('blur');
        }
        if (option !== false && this.$refs[option]) {
          this.$refs[option].classList.remove('blur');
        }
      }
    },
    unhighlight() {
      if (this.selectedVehicle !== 'eRod') {
        if (this.$refs.front) {
          this.$refs.front.classList.remove('blur');
        }
        if (this.$refs.back) {
          this.$refs.back.classList.remove('blur');
        }
        if (this.$refs.color) {
          this.$refs.color.classList.remove('blur');
        }
        if (this.$refs.rim) {
          this.$refs.rim.classList.remove('blur');
        }
        if (this.$refs.windshield) {
          this.$refs.windshield.classList.remove('blur');
        }
        if (this.$refs.wing) {
          this.$refs.wing.classList.remove('blur');
        }
        if (this.$refs.sidepanel) {
          this.$refs.sidepanel.classList.remove('blur');
        }
        if (this.$refs.door) {
          this.$refs.door.classList.remove('blur');
        }
        if (this.$refs.roof) {
          this.$refs.roof.classList.remove('blur');
        }
      }
    },
    showConfiguratorSelector(option, event) {
      event.preventDefault();
      this.highlight(false);
      this.selectedOption = option;
    },
    closeSelector() {
      this.selectedOption = null;
    },
    selectElement(payload) {
      if (payload.type === 'colors') {
        this.selectColor(payload.elementId);
      } else if (payload.type === 'front') {
        this.selectFront(payload.elementId);
      } else if (payload.type === 'rim') {
        this.selectRim(payload.elementId);
      } else if (payload.type === 'windshield') {
        this.selectWindshield(payload.elementId);
      } else if (payload.type === 'wing') {
        this.selectWing(payload.elementId);
      } else if (payload.type === 'sidepanel') {
        this.selectSidepanel(payload.elementId);
      } else if (payload.type === 'door') {
        this.selectDoor(payload.elementId);
      } else if (payload.type === 'foiling') {
        this.selectFoiling(payload.elementId);
      } else if (payload.type === 'back') {
        this.selectBack(payload.elementId);
      } else if (payload.type === 'roof') {
        this.selectRoof(payload.elementId);
      } else {
        console.log('selected element not defined'); // eslint-disable-line
      }
      setTimeout(() => {
        this.closeSelector();
      }, 500);
    },
    selectColor(colorId) {
      this.$store.commit('SELECT_COLOR', colorId);
      this.$track(this.$options.name, 'choose-color', this.getOptionNameById('colors', colorId), this.selectedType, this.selectedVehicle);
    },
    selectFront(frontId) {
      this.$store.commit('SELECT_FRONT', frontId);
      this.$track(this.$options.name, 'choose-front', this.getOptionNameById('fronts', frontId), this.selectedType, this.selectedVehicle);
    },
    selectBack(backId) {
      this.$store.commit('SELECT_BACK', backId);
      this.$track(this.$options.name, 'choose-back', this.getOptionNameById('backs', backId), this.selectedType, this.selectedVehicle);
    },
    selectRim(rimId) {
      this.$store.commit('SELECT_RIM', rimId);
      this.$track(this.$options.name, 'choose-rim', this.getOptionNameById('rims', rimId), this.selectedType, this.selectedVehicle);
    },
    selectWindshield(windshieldId) {
      this.$store.commit('SELECT_WINDSHIELD', windshieldId);
      this.$track(this.$options.name, 'choose-windshield', this.getOptionNameById('windshields', windshieldId), this.selectedType, this.selectedVehicle);
    },
    selectWing(wingId) {
      this.$store.commit('SELECT_WING', wingId);
      this.$track(this.$options.name, 'choose-wing', this.getOptionNameById('wings', wingId), this.selectedType, this.selectedVehicle);
    },
    selectSidepanel(sidepanelId) {
      this.$store.commit('SELECT_SIDEPANEL', sidepanelId);
      this.$track(this.$options.name, 'choose-sidepanel', this.getOptionNameById('sidepanels', sidepanelId), this.selectedType, this.selectedVehicle);
    },
    selectFoiling(foilingId) {
      if (foilingId > 1) {
        this.fadeInFoiling();
      }
      this.$store.commit('SELECT_FOILING', foilingId);
      this.$track(this.$options.name, 'choose-foiling', this.getOptionNameById('foilings', foilingId), this.selectedType, this.selectedVehicle);
    },
    selectDoor(doorId) {
      this.$store.commit('SELECT_DOOR', doorId);
      this.$track(this.$options.name, 'choose-door', this.getOptionNameById('doors', doorId), this.selectedType, this.selectedVehicle);
    },
    selectRoof(roofId) {
      this.$store.commit('SELECT_ROOF', roofId);
      this.$track(this.$options.name, 'choose-roof', this.getOptionNameById('roofs', roofId), this.selectedType, this.selectedVehicle);
    },
    escapeClick(event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        event.preventDefault();
        this.closeSelector();
      }
    },
    getOptionNameById(option, id) {
      const result = this.$kyburz[option].find((obj) => obj.id === id);
      return result.name.de;
    },
    leaveAnimation() {
      const previewSelectors = document.getElementsByClassName('configurator-preview__selector');
      const vehicleTitle = document.getElementsByClassName('vehicle-title');
      previewSelectors.forEach((element) => {
        element.classList.add('hidden');
      });
      vehicleTitle.forEach((element) => {
        element.classList.add('hidden');
      });

      const preview = document.getElementsByClassName('configurator-preview__images');
      preview.forEach((element) => {
        element.classList.add('moved');
      });
    },
    fadeInFoiling() {
      if (this.$refs && Object.prototype.hasOwnProperty.call(this.$refs, 'foiling')) {
        setTimeout(() => {
          this.$refs.foiling.classList.add('visible');
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">

.configurator-preview {
  position: relative;

  &__images {
    position: relative;
    width: 1200px;
    height: 800px;
    margin: 0 auto;
    transition-delay: 2s;
    transition: all 1s ease-in;

    &.moved {
      transform: translate3d(-100vw, 7.5vh, 0);
    }

    &--dxc {

      .color-ral {
        z-index: 0;

        &::before {
          content: '';
          width: 1200px;
          height: 800px;
          position: absolute;
          top: 0;
          left: 0;
          border: 20px solid white;
        }
      }

      #image-colors {
        z-index: 1;
      }

      #image-back {
        z-index: 2;
      }

      #image-front {
        z-index: 3;
      }

      #image-roof {
        z-index: 4;
      }

      #image-roof-fixation {
        z-index: 5;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    &.front,
    &.back,
    &.rim,
    &.windshield,
    &.wing,
    &.sidepanel,
    &.door {
      z-index: 1;
    }

    &.foiling {
      height: 95%;
      width: 95%;
      top: 5px;
      left: 5px;
      opacity: 0;

      &.visible {
        opacity: 1;
        transition: opacity .3s ease-in;
      }
    }

  }

  .color-ral {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__model-name {
    text-align: center;
    margin: 0;
    position: relative;
    top: -80px;
  }

  &__selector {
    display: block;
    width: 11rem;
    position: absolute;
    z-index: 10;
    color: #727272;
    text-decoration: none;
    font-size: 16px;
    border: 5px solid transparent;
    transition: all .3s ease-in-out;

    &.hidden {
      display: none;
    }

    &--front {
      top: 10%;
      left: 30%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s linear;
      width: 150px;
      height: 173px;

      img {
        display: block;
        position: absolute;
        top: 87px;
        left: 10px;
        width: 47px;
        height: 79px;
      }
    }

    &--dxc-front {
      top: 25%;
      left: 5%;

      img {
        top: 78px;
        left: 83px;
        width: 67px;
        height: 65px;
      }
    }

    &--roof {
      top: 20%;
      left: 41%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s linear;
      width: 150px;
      height: 173px;

      img {
        display: none;
        position: absolute;
        top: 87px;
        left: 10px;
        width: 47px;
        height: 79px;
      }
    }

    &--back {
      top: 7%;
      left: 70%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s ease-in-out;
      width: 180px;
      height: 145px;

      img {
        display: block;
        position: absolute;
        top: 81px;
        right: 0;
        width: 68px;
        height: 56px;
      }
    }

    &--dxc-back {
      top: 23%;
      left: 85%;
      width: 140px;
      height: 110px;

      img {
        display: none;
      }
    }

    &--color {
      top: 50%;
      left: 40%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s ease-in-out;
      width: 118px;
      height: 177px;

      img {
        display: block;
        position: absolute;
        top: 100px;
        left: 53px;
        width: 44px;
        height: 75px;
      }

      .txt {
        width: 60px;
        margin: 0 auto;
      }
    }

    &--erod-color {
      top: 30%;
      left: 27%;
    }

    &--erod-foiling {
      top: 43%;
      left: 12%;

      .txt {
        width: 80px;
      }

      img {
        transform: rotate(-45deg);
        transform-origin: top left;
        top: 99px;
        left: 64px;
      }
    }

    &--PLUS-color {
      top: 34%;
      left: 15%;
    }

    &--dxc-color {
      top: 58%;
      left: 35%;
    }

    &--rim {
      top: 67%;
      left: 1.5%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s ease-in-out;
      width: 180px;
      height: 145px;

      img {
        display: block;
        position: absolute;
        top: 98px;
        left: 90px;
        // width: 68px;
        // height: 56px;
      }
    }

    &--PLUS-rim {
      top: 72%;
      left: 6.5%;
    }

    &--windshield {
      top: 25%;
      left: 42%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s ease-in-out;
      width: 180px;
      height: 145px;

      img {
        display: block;
        position: absolute;
        top: 101px;
        right: 48px;
      }
    }

    &--wing {
      top: 36%;
      right: -7%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s linear;
      width: 150px;
      height: 173px;

      img {
        display: block;
        position: absolute;
        top: 87px;
        left: 10px;
        width: 47px;
        height: 79px;
      }
    }

    &--sidepanel {
      top: 74%;
      left: 60%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s ease-in-out;
      width: 180px;
      height: 145px;

      .dot {
        top: 60px !important;
      }

      .txt {
        margin-top: 80px;
      }

      img {
        display: block;
        position: absolute;
        top: -20px;
        left: 17px;
        width: 68px;
        height: 76px;
      }
    }

    &--door {
      display: none;
      top: 20%;
      right: 6%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      transform-origin: center center;
      transition: all .3s linear;
      width: 150px;
      height: 173px;

      img {
        display: block;
        position: absolute;
        top: 91px;
        left: -15px;
        width: 79px;
        height: 47px;
      }
    }

    &--PLUS-rim {
      display: none;
    }

    .dot {
      height: 20px;
      width: 20px;
      background-color: #fff;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 70px;
      border: 4px solid #1b49a6;
    }

    .txt {
      display: block;
      height: auto;
      text-align: center;
      padding-top: .5rem;
      font-size: 18px;
    }

    &:hover {
      text-decoration: none;
      transform:  translate3d(-50%, -50%, 0) scale(1.25);
      transition: all 0.5s cubic-bezier(0, 1, 1, 1);
    }
  }
}

.vehicle-title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  transition: all .3s ease-in-out;

  &.hidden {
    opacity: 0;
  }
}

.blur {
  transition: filter .3s ease-in-out;
  filter: blur(3px);
}

.configurator-preview__images--dxc {
  .blur {
    transition: none;
    filter: none;
  }
}

.front, .back, .rim, .windshield, .all, .door {
  transition: filter .3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media (max-width: 1440px) { // 13" MacBook
  .configurator-preview__selector--dxc-color {
    top: 58%;
    left: 36%;
  }
  .configurator-preview__selector--dxc-back {
    top: 5%;
    left: 100%;
  }
}

@media (max-width: 1264px) { // iPad landscape

  .configurator-preview__images {
    width: 640px;
    height: 426.66666666666px;
    margin: 0 auto;

    &--dxc {

      .color-ral {

        &::before {
          content: '';
          width: 640px;
          height: 426.66666666666px;
        }
      }
    }
  }

  .configurator-preview__selector {
    &--front {
      width: 121px;
      height: 152px;
      top: 5%;
      left: 28%;

      img {
        top: 85px;
        left: 5px;
        width: 37px;
        height: 66px;
      }

      .txt {
        width: 120px;
      }
    }

    &--dxc-front {
      top: 15%;
      left: 0%;

      img {
        top: 85px;
        left: 67px;
        width: 37px;
        height: 38px;
      }
    }

    &--back {
      width: 130px;
      height: 125px;
      top: 3%;
      left: 72%;

      img {
        top: 82px;
        right: 3px;
        width: 42px;
        height: 46px;
      }
    }

    &--dxc-back {
      top: -2%;
      left: 102%;
    }

    &--color {
      top: 47%;
      left: 41%;
      width: 98px;
      height: 147px;

      img {
        top: 94px;
        left: 43px;
        width: 34px;
        height: 55px;
      }
    }

    &--dxc-color {
      top: 32%;
    }

    &--roof {
      top: 0%;
      left: 34%;
    }

    &--rim {
      top: 61%;
      left: -2%;
      width: 98px;
      height: 147px;

      img {
        top: 94px;
        left: 54px;
        width: 49px;
        height: 28px;
      }
    }

    &--erod-color {
      top: 27%;
      left: 24%;
    }

    &--erod-foiling {
      top: 39%;
      left: 10%;
    }

    &--windshield {
      top: 11%;
      left: 42%;
    }

    &--wing {
      top: 28%;
      right: -22%;
    }

    &--sidepanel {
      top: 76%;
      left: 67%;
    }

    &--PLUS-rim {
      top: 65%;
      left: 4%;
    }

    &--PLUS-color {
      top: 31%;
      left: 14%;
    }

    &--door {
      right: -8%;
    }
  }

  .configurator-preview__model-name {
    font-size: 24px;
  }

  .vehicle-title {
    bottom: -40px;
  }
}

@media (max-width: 960px) and (orientation:portrait) { // iPad portrait

  .configurator-preview__images {
    width: 700px;
    height: 466.66666666666px;
    margin: 0 auto;

    &--erod,
    &--dxc {
      margin-left: 0;
    }

    &--dxc {

      .color-ral {

        &::before {
          content: '';
          width: 700px;
          height: 466.66666666666px;
        }
      }
    }
  }

  .configurator-preview__selector {
    &--front {
      width: 125px;
      height: 152px;
      top: 5%;

      img {
        top: 82px;
        left: 5px;
        width: 37px;
        height: 66px;
      }
    }

    &--dxc-front {
      top: 15%;
      left: 8%;

      img {
        top: 82px;
        left: 72px;
        width: 37px;
        height: 45px;
      }
    }

    &--back {
      width: 130px;
      height: 125px;
      top: 3%;

      img {
        top: 83px;
        right: 4px;
        width: 42px;
        height: 46px;
      }
    }

    &--dxc-back {
      top: -5%;
      left: 82%;
    }

    &--color {
      top: 44%;
      left: 42%;
      width: 98px;
      height: 147px;

      img {
        top: 98px;
        left: 42px;
        width: 34px;
        height: 55px;
      }
    }

    &--dxc-color {
      top: 36%;
      left: 40%;
    }

    &--rim {
      top: 69%;
      left: 0;
      width: 98px;
      height: 147px;

      img {
        top: 88px;
        left: 54px;
        width: 59px;
        height: 31px;
      }
    }

    &--erod-color {
      top: 27%;
      left: 25%;
    }

    &--erod-foiling {
      top: 39%;
      left: 11%;
    }

    &--wing {
      top: 32%;
      right: -16%;
    }

    &--PLUS-color {
      top: 29%;
      left: 15%;
    }

    &--PLUS-rim {
      top: 66%;
      left: 3%;
    }
  }

  .configurator-preview__model-name {
    font-size: 24px;
  }
}

@media (max-width: 600px) and (orientation:portrait) { // iPhone 6/7/8 Plus portrait

  .vehicle-title {
    bottom: -20px;
    position: relative;
    font-size: 20px;
  }

  .configurator-preview__images {
    width: 400px;
    height: 266.6666666666px;
    margin: 0 auto;
    margin-top: 80px;

    &--PLUS {
      margin-top: 40px;
    }

    &--dxc {
      margin-top: 60px;

      .color-ral {

        &::before {
          content: '';
          width: 400px;
          height: 266.6666666666px;
        }
      }
    }
  }

  .configurator-preview__selector {
    transform: translate(-50%, -50%) scale(1.3);

    img {
      display: none;
    }

    &--front {
      top: 23%;
      left: 19%;
      width: 97px;
      height: 130px;

      .txt {
        font-size: 10px;
      }

      .dot {
        top: 43px;
        left: 68%;
      }
    }

    &--dxc-front {
      top: 19%;
      left: 10%;
    }

    &--back {
      top: 6%;
      left: 79%;
      width: 100px;
      height: 86px;

      .txt {
        font-size: 10px;
      }

      .dot {
        top: 42px;
      }
    }

    &--dxc-back {
      top: 4%;
      left: 76%
    }

    &--roof {
      top: 10%;
      left: 40%;
      width: 80px;
      height: 80px;

      .txt {
        font-size: 10px;
      }

      .dot {
        top: 44px;
      }
    }

    &--dxc-roof {
      top: 0;
    }

    &--color {
      top: 84%;
      left: 56%;
      width: 64px;
      height: 73px;

      .txt {
        position: absolute;
        top: 20px;
        left: -5px;
        font-size: 10px;
      }

      .dot {
        top: 0;
      }
    }

    &--dxc-color {
      top: 95%;
      left: 46%;
    }

    &--rim {
      top: 96%;
      left: 16%;
      width: 64px;
      height: 73px;

      .txt {
        position: absolute;
        top: 17px;
        left: 9px;
        font-size: 10px;
      }

      .dot {
        top: 0;
      }
    }

    &--PLUS-rim {
      top: 97%;
      left: 80%;
    }

    &--erod-color {
      top: 34%;
      left: 34%;
      height: 61px;

      .txt {
        top: 0;
        left: -4px;
        padding-top: 0;
      }

      .dot {
        top: 33px;
      }
    }

    &--erod-foiling {
      top: 44%;
      left: 16%;
      width: 87px;

      .txt {
        top: 0;
        left: -4px;
        padding-top: 0;
      }

      .dot {
        top: 33px;
      }
    }

    &--windshield {
      top: 23%;
      left: 51%;
      width: 105px;
      height: 57px;

      .txt {
        position: absolute;
        top: 0;
        left: 3px;
        font-size: 10px;
        padding-top: 0;
      }

      .dot {
        top: 32px;
      }
    }

    &--wing {
      top: 35%;
      right: -27px;
      width: 63px;
      height: 57px;

      .txt {
        position: absolute;
        top: 0;
        left: 7px;
        font-size: 10px;
        padding-top: 0;
      }

      .dot {
        top: 32px;
      }
    }

    &--sidepanel {
      width: 50px;
      height: 50px;
      top: 70%;
      left: 59%;

      .txt {
        position: absolute;
        top: 25px;
        left: 0;
        font-size: 10px;
        padding-top: 0;
        margin-top: 0;
      }

      .dot {
        top: 0 !important;
        left: 25px;
      }
    }

    &--door {
      width: 50px;
      height: 62px;
      top: 5px;
      left: 70%;
      display: flex;

      .txt {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-size: 10px;
        padding-top: 0;
        margin-top: 0;
      }

      .dot {
        top: 30px;
      }
    }

    &--PLUS-color {
      top: 24%;
      left: 20%;

      .dot {
        top: 33px;
      }

      .txt {
        top: 0;
        padding-top: 0;
      }
    }

  }
}

@media (max-width: 375px) { // iPhone X and smaller portrait
  .configurator-preview__images {
    width: 350px;
    height: 233.3333333px;
    margin: 0 auto;
    margin-top: 40px;

    &--dxc {

      .color-ral {

        &::before {
          content: '';
          width: 350px;
          height: 233.3333333px;
        }
      }
    }
  }
}

@media (max-width: 320px) { // iPhone X and smaller portrait
  .configurator-preview {
    top: -61px;
  }

  .configurator-preview__images {
    width: 300px;
    height: 203.333333px;
    margin: 0 auto;
    margin-top: 80px;

    &--dxc {

      .color-ral {

        &::before {
          content: '';
          width: 300px;
          height: 203.333333px;
        }
      }
    }
  }
}
</style>
