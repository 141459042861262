<template>
  <div
    class="d-flex"
    :class="{'flex-grow-1': showDivider}"
  >
    <div
      v-if="step.id === currentStep"
      class="current-step-label"
    >
      {{ step.id }}
    </div>
    <v-stepper-step
      :complete="step.complete"
      :step="step.id"
      color="#e60000"
      :class="{ 'clickable': (step.id === 1 || previousStepsComplete) }"
      @click="setCurrentStep(step.id)"
    >
      <span
        :class="{ 'current-step-text': step.id === currentStep }"
      >
        {{ step.name[$i18n.locale] }}
      </span>
    </v-stepper-step>
    <v-divider
      v-if="showDivider"
      :color="previousStepsComplete ? '#e60000' : null"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
  name: 'StepperStep',
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentStep: (state) => state.currentStep,
      steps: (state) => state.steps,
    }),
    previousStepsComplete() {
      const currentStepIndex = this.steps.findIndex((step) => step.id === this.step.id);
      for (let i = 0; i < currentStepIndex; i += 1) {
        if (this.steps[i].complete === false) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    setCurrentStep(step) {
      if (this.previousStepsComplete && step !== this.currentStep) {
        this.$router.push({ name: `step-${step}` }).catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">

.v-stepper__step {
  flex-grow: 0;
  position: relative;
  padding-top: 12px !important;
  padding-bottom: 12px !important;

  &.clickable {
    cursor: pointer;
  }
}

.stepper-header {
  height: 48px !important;
}

.current-step-label {
  color: white;
  background-color: #e60000;
  position: absolute;
  z-index: 1;
  width: 25px;
  height: 25px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 24px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding-top: 4px;
  text-align: center;
}

.current-step-text {
  color: #e60000;
}

.theme--light.v-stepper .v-stepper__label {
  color: #666 !important;
}

.theme--dark.v-stepper .v-stepper__label {
  color: white !important;
}

.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: none !important;
}

@media (max-width: 1024px) {
  .v-stepper__step {
    padding-top: 8px;
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .v-stepper__step {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
