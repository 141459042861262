/* eslint-disable */
function ConfigPlugin (Vue) {

  // atm used in Termination.vue to select the host depending on env
  Vue.prototype.$isDev = process.env.NODE_ENV === 'development';

  // show art.-nr.?
  Vue.prototype.$debug = false;
  if (process.env.NODE_ENV === 'development') {
    Vue.prototype.$debug = false;
  }
}

ConfigPlugin.version = '0.0.1';

module.exports = ConfigPlugin;
