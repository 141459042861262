<template>
  <v-container
    class="summary__container pt-0 pt-md-16"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
        class="d-flex flex-column align-center"
      >
        <NumberInput
          :count="countVehicles"
          :label="$t('content.countVehicles')"
          class="mb-6 mb-sm-12 mt-4 pl-2"
          @setcount="setCountVehicles"
        />
        <v-simple-table
          class="summary align-self-start align-self-sm-center align-self-lg-end"
          :dense="$vuetify.breakpoint.xsOnly"
        >
          <tbody>
            <tr
              v-if="vehicle.deliveryTime"
            >
              <td>
                {{ $t('content.delivery-expected') }}:
              </td>
              <td>{{ deliveryDate }}</td>
            </tr>
            <tr>
              <td>
                {{ $t('content.model') }}: <b>{{ vehicle.name[$i18n.locale] }} {{ versionName }}</b>
              </td>
              <td>{{ vehicleVersionPrice | toCurrency }}</td>
            </tr>
            <tr v-if="order.front !== null">
              <td>{{ $t('content.front') }}: <b>{{ front.name[$i18n.locale] }}</b> </td>
              <td>{{ front.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.back !== null">
              <td>{{ $t('content.back') }}: <b>{{ back.name[$i18n.locale] }}</b> </td>
              <td>{{ back.price | toCurrency }}</td>
            </tr>
            <tr>
              <td>
                {{ $t('content.color') }}: <b>{{ color.name[$i18n.locale] }}</b>
                <span
                  v-if="color.id === 99"
                ><b>&nbsp;({{ order.colorRal }})</b></span>
              </td>
              <td>{{ color.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.windshield !== null">
              <td>{{ $t('content.windshield') }}: <b>{{ windshield.name[$i18n.locale] }}</b> </td>
              <td>{{ windshield.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.wing !== null">
              <td>{{ $t('content.wing') }}: <b>{{ wing.name[$i18n.locale] }}</b> </td>
              <td>{{ wing.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.sidepanel !== null">
              <td>{{ $t('content.sidepanel') }}: <b>{{ sidepanel.name[$i18n.locale] }}</b> </td>
              <td>{{ sidepanel.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.foiling !== null">
              <td>{{ $t('content.foiling') }}: <b>{{ foiling.name[$i18n.locale] }}</b></td>
              <td>{{ foiling.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.rim !== null">
              <td>{{ $t('content.rim') }}: <b>{{ rim.name[$i18n.locale] }}</b></td>
              <td>{{ rim.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.door !== null">
              <td>{{ $t('content.door') }}: <b>{{ door.name[$i18n.locale] }}</b></td>
              <td>{{ door.price | toCurrency }}</td>
            </tr>
            <tr v-if="order.roof !== null">
              <td>{{ $t('content.roof') }}: <b>{{ roof.name[$i18n.locale] }}</b></td>
              <td>{{ roof.price | toCurrency }}</td>
            </tr>
            <tr v-if="frontWindow !== null">
              <td>{{ $t('content.frontWindow') }}: <b>{{ frontWindow.name[$i18n.locale] }}</b></td>
              <td>{{ frontWindow.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="kneeblanket !== null">
              <td>{{ $t('content.kneeblanket') }}: <b>{{ kneeblanket.name[$i18n.locale] }}</b></td>
              <td>{{ kneeblanket.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="glove !== null">
              <td>{{ $t('content.glove') }}: <b>{{ glove.name[$i18n.locale] }}</b></td>
              <td>{{ glove.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="battery !== null">
              <td>{{ $t('content.battery') }}: <b>{{ battery.name[$i18n.locale] }}</b></td>
              <td>{{ battery.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="bigBattery !== null">
              <td>{{ $t('content.bigBattery') }}: <b>{{ bigBattery.name[$i18n.locale] }}</b></td>
              <td>{{ bigBattery.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="marking !== null">
              <td>{{ $t('content.marking') }}: <b>{{ marking.name[$i18n.locale] }}</b></td>
              <td>{{ marking.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="cover !== null">
              <td>{{ $t('content.cover') }}: <b>{{ cover.name[$i18n.locale] }}</b></td>
              <td>{{ cover.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="blackRimSet !== null">
              <td>{{ $t('content.blackRimSet') }}: <b>{{ blackRimSet.name[$i18n.locale] }}</b></td>
              <td>{{ blackRimSet.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="caneholder !== null">
              <td>{{ $t('content.caneholder') }}: <b>{{ caneholder.name[$i18n.locale] }}</b></td>
              <td>{{ caneholder.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="seat !== null">
              <td>{{ $t('content.seat') }}: <b>{{ seat.name[$i18n.locale] }}</b></td>
              <td>{{ seat.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="charger !== null">
              <td>{{ $t('content.charger') }}: <b>{{ charger.name[$i18n.locale] }}</b></td>
              <td>{{ charger.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="bucketSeat !== null">
              <td>{{ $t('content.bucketSeat') }}: <b>{{ bucketSeat.name[$i18n.locale] }}</b></td>
              <td>{{ bucketSeat.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="tire !== null">
              <td>{{ $t('content.tire') }}: <b>{{ tire.name[$i18n.locale] }}</b></td>
              <td>{{ tire.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="schukoAdapter !== null">
              <td>{{ $t('content.schukoAdapter') }}: <b>{{ schukoAdapter.name[$i18n.locale] }}</b></td>
              <td>{{ schukoAdapter.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="differential !== null">
              <td>{{ $t('content.differential') }}: <b>{{ differential.name[$i18n.locale] }}</b></td>
              <td>{{ differential.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="adapterCable !== null">
              <td>{{ $t('content.adapterCable') }}: <b>{{ adapterCable.name[$i18n.locale] }}</b></td>
              <td>{{ adapterCable.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="weatherProtection !== null">
              <td>{{ $t('content.weatherProtection') }}: <b>{{ weatherProtection.name[$i18n.locale] }}</b></td>
              <td>{{ weatherProtection.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="suitcase !== null">
              <td>{{ $t('content.suitcase') }}: <b>{{ suitcase.name[$i18n.locale] }}</b></td>
              <td>{{ suitcase.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="fleetManagement !== null">
              <td>{{ $t('content.fleetManagement') }}: <b>{{ fleetManagement.name[$i18n.locale] }}</b></td>
              <td>{{ fleetManagement.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="handling !== null">
              <td>{{ $t('content.handling') }}: <b>{{ handling.name[$i18n.locale] }}</b></td>
              <td>{{ handling.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="doorOpenHolder !== null">
              <td>{{ $t('content.doorOpenHolder') }}: <b>{{ doorOpenHolder.name[$i18n.locale] }}</b></td>
              <td>{{ doorOpenHolder.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="naviPlugSocket !== null">
              <td>{{ $t('content.naviPlugSocket') }}: <b>{{ naviPlugSocket.name[$i18n.locale] }}</b></td>
              <td>{{ naviPlugSocket.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="usbPlugSocket !== null">
              <td>{{ $t('content.usbPlugSocket') }}: <b>{{ usbPlugSocket.name[$i18n.locale] }}</b></td>
              <td>{{ usbPlugSocket.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="towCoupling !== null">
              <td>{{ $t('content.towCoupling') }}: <b>{{ towCoupling.name[$i18n.locale] }}</b></td>
              <td>{{ towCoupling.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="sunVisor !== null">
              <td>{{ $t('content.sunVisor') }}: <b>{{ sunVisor.name[$i18n.locale] }}</b></td>
              <td>{{ sunVisor.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="headrest !== null">
              <td>{{ $t('content.headrest') }}: <b>{{ headrest.name[$i18n.locale] }}</b></td>
              <td>{{ headrest.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="rollator !== null">
              <td>{{ $t('content.rollator') }}: <b>{{ rollator.name[$i18n.locale] }}</b></td>
              <td>{{ rollator.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="naviDevice !== null">
              <td>{{ $t('content.naviDevice') }}: <b>{{ naviDevice.name[$i18n.locale] }}</b></td>
              <td>{{ naviDevice.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="trailer !== null">
              <td>{{ $t('content.trailer') }}: <b>{{ trailer.name[$i18n.locale] }}</b></td>
              <td>{{ trailer.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="rainProtection !== null">
              <td>{{ $t('content.rainProtection') }}: <b>{{ rainProtection.name[$i18n.locale] }}</b></td>
              <td>{{ rainProtection.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="springSeat !== null">
              <td>{{ $t('content.springSeat') }}: <b>{{ springSeat.name[$i18n.locale] }}</b></td>
              <td>{{ springSeat.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="rekuModule !== null">
              <td>{{ $t('content.rekuModule') }}: <b>{{ rekuModule.name[$i18n.locale] }}</b></td>
              <td>{{ rekuModule.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="keylessGo !== null">
              <td>{{ $t('content.keylessGo') }}: <b>{{ keylessGo.name[$i18n.locale] }}</b></td>
              <td>{{ keylessGo.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="winterWheel !== null">
              <td>{{ $t('content.winterWheel') }}: <b>{{ winterWheel.name[$i18n.locale] }}</b></td>
              <td>{{ winterWheel.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="radio !== null">
              <td>{{ $t('content.radio') }}: <b>{{ radio.name[$i18n.locale] }}</b></td>
              <td>{{ radio.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="heatedGrip !== null">
              <td>{{ $t('content.heatedGrip') }}: <b>{{ heatedGrip.name[$i18n.locale] }}</b></td>
              <td>{{ heatedGrip.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="heatedFloor !== null">
              <td>{{ $t('content.heatedFloor') }}: <b>{{ heatedFloor.name[$i18n.locale] }}</b></td>
              <td>{{ heatedFloor.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="distressButton !== null">
              <td>{{ $t('content.distressButton') }}: <b>{{ distressButton.name[$i18n.locale] }}</b></td>
              <td>{{ distressButton.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="accessoryRoof !== null">
              <td>{{ $t('content.accessoryRoof') }}: <b>{{ accessoryRoof.name[$i18n.locale] }}</b></td>
              <td>{{ accessoryRoof.price | toCurrencyClear }}</td>
            </tr>
            <tr v-if="singleInspection !== null">
              <td>{{ $t('content.singleInspection') }}: <b>{{ singleInspection.name[$i18n.locale] }}</b></td>
              <td>{{ singleInspection.price | toCurrencyClear }}</td>
            </tr>
            <tr>
              <td>{{ $t('content.delivery') }}: <b v-html="delivery.name[$i18n.locale]" /></td> <!-- eslint-disable-line vue/no-v-html -->
              <td v-if="order.delivery === 2">
                *
              </td>
              <td v-else>
                {{ delivery.price | toCurrencyClear }}
              </td>
            </tr>
            <tr class="total-vehicle">
              <td>Total <b>{{ $t('content.vehicle') }}</b></td>
              <td>
                {{ totalVehicle | toCurrency }}<span v-if="order.delivery === 2">&nbsp;**</span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('content.instruction') }}: <b>{{ instruction.name[$i18n.locale] }}</b></td>
              <td>{{ instruction.price | toCurrencyClear }}</td>
            </tr>
            <tr>
              <td>{{ $t('content.countVehicles') }}: </td>
              <td><b>{{ order.countVehicles }}</b></td>
            </tr>
            <tr class="summary">
              <td>{{ $t('content.totaltotal') }}</td>
              <td>
                <b>{{ total | toCurrency }}<span v-if="order.delivery === 2">&nbsp;**</span></b>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          v-if="order.delivery === 2"
          class="delivery-remarks mt-2"
        >
          <p v-html="$t('content.delivery-export')" />  <!-- eslint-disable-line vue/no-v-html -->
        </div>
      </v-col>

      <v-col
        cols="12"
        lg="6"
        class="d-flex justify-center align-center pt-0 pb-12 pb-sm-0 mb-12 mb-sm-0"
      >
        <VehiclePreview />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import * as Sentry from '@sentry/vue';

import VehiclePreview from '../components/VehiclePreview.vue';
import NumberInput from '../components/NumberInput.vue';

export default {
  name: 'Summary', // step-6
  components: {
    VehiclePreview,
    NumberInput,
  },
  computed: {
    ...mapState({
      order: (state) => state.order,
      countVehicles: (state) => state.order.countVehicles,
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    total() {
      return this.$store.getters.orderTotal;
    },
    vehicle() {
      const vehicle = this.$kyburz.vehicles.find((veh) => veh.id === this.order.vehicle);
      return vehicle;
    },
    versionName() {
      const version = this.vehicle.versions.find((ver) => ver.id === this.order.version);
      return version.name[this.$i18n.locale];
    },
    vehicleVersionPrice() {
      const version = this.vehicle.versions.find((ver) => ver.id === this.order.version);
      return version.price;
    },
    deliveryDate() {
      const date = dayjs().add(this.vehicle.deliveryTime, 'week');
      const weekDay = date.format('d');
      // if day is sunday, set to monday
      if (weekDay === 0) {
        date.add(1, 'day');
      }
      // if day is saturday, set to monday
      if (weekDay === 7) {
        date.add(2, 'day');
      }
      return date.format('DD.MM.YYYY');
    },
    front() {
      if (this.order.front === null) {
        return null;
      }
      const front = this.$kyburz.fronts.find((fro) => fro.id === this.order.front);
      return {
        name: front.name,
        price: front[this.order.vehicle].price,
      };
    },
    back() {
      if (this.order.back === null) {
        return null;
      }
      const back = this.$kyburz.backs.find((hec) => hec.id === this.order.back);
      return {
        name: back.name,
        price: back[this.order.vehicle].price,
      };
    },
    color() {
      const color = this.$kyburz.colors.find((col) => col.id === this.order.color);
      return {
        id: color.id,
        name: color.name,
        price: color[this.order.vehicle].price,
      };
    },
    rim() {
      if (this.order.rim === null) {
        return null;
      }
      const rim = this.$kyburz.rims.find((r) => r.id === this.order.rim);
      return {
        name: rim.name,
        price: rim[this.order.vehicle].price,
      };
    },
    windshield() {
      if (this.order.windshield === null) {
        return null;
      }
      const windshield = this.$kyburz.windshields.find((r) => r.id === this.order.windshield);
      return {
        name: windshield.name,
        price: windshield[this.order.vehicle].price,
      };
    },
    wing() {
      if (this.order.wing === null) {
        return null;
      }
      const wing = this.$kyburz.wings.find((r) => r.id === this.order.wing);
      return {
        name: wing.name,
        price: wing[this.order.vehicle].price,
      };
    },
    sidepanel() {
      if (this.order.sidepanel === null) {
        return null;
      }
      const sidepanel = this.$kyburz.sidepanels.find((r) => r.id === this.order.sidepanel);
      return {
        name: sidepanel.name,
        price: sidepanel[this.order.vehicle].price,
      };
    },
    door() {
      if (this.order.door === null) {
        return null;
      }
      const door = this.$kyburz.doors.find((r) => r.id === this.order.door);
      return {
        name: door.name,
        price: door[this.order.vehicle].price,
      };
    },
    roof() {
      if (this.order.roof === null) {
        return null;
      }
      const roof = this.$kyburz.roofs.find((r) => r.id === this.order.roof);
      return {
        name: roof.name,
        price: roof[this.order.vehicle].price,
      };
    },
    foiling() {
      if (this.order.foiling === null) {
        return null;
      }
      const foiling = this.$kyburz.foilings.find((r) => r.id === this.order.foiling);
      return {
        name: foiling.name,
        price: foiling[this.order.vehicle].price,
      };
    },
    frontWindow() {
      if (typeof this.order.accessories.frontWindow === 'undefined') {
        return null;
      }
      const frontWindow = this.$kyburz.accessories.frontWindows.find((fw) => fw.value === this.order.accessories.frontWindow);
      return {
        name: frontWindow.name,
        price: frontWindow.price,
      };
    },
    kneeblanket() {
      if (typeof this.order.accessories.kneeblanket === 'undefined') {
        return null;
      }
      const kneeblanket = this.$kyburz.accessories.kneeblankets.find((knie) => knie.value === this.order.accessories.kneeblanket);
      return {
        name: kneeblanket.name,
        price: kneeblanket.price,
      };
    },
    glove() {
      if (typeof this.order.accessories.glove === 'undefined') {
        return null;
      }
      const glove = this.$kyburz.accessories.gloves.find((l) => l.value === this.order.accessories.glove);
      return {
        name: glove.name,
        price: glove.price,
      };
    },
    battery() {
      if (typeof this.order.accessories.battery === 'undefined') {
        return null;
      }
      const battery = this.$kyburz.accessories.batteries.find((bat) => bat.value === this.order.accessories.battery);
      return {
        name: battery.name,
        price: battery.price,
      };
    },
    bigBattery() {
      if (typeof this.order.accessories.bigBattery === 'undefined') {
        return null;
      }
      const bigBattery = this.$kyburz.accessories.bigBatteries.find((bat) => bat.value === this.order.accessories.bigBattery);
      return {
        name: bigBattery.name,
        price: bigBattery.price,
      };
    },
    marking() {
      if (typeof this.order.accessories.marking === 'undefined') {
        return null;
      }
      const marking = this.$kyburz.accessories.markings.find((besch) => besch.value === this.order.accessories.marking);
      return {
        name: marking.name,
        price: marking.price,
      };
    },
    cover() {
      if (typeof this.order.accessories.cover === 'undefined') {
        return null;
      }
      const cover = this.$kyburz.accessories.covers.find((besch) => besch.value === this.order.accessories.cover);
      return {
        name: cover.name,
        price: cover.price,
      };
    },
    blackRimSet() {
      if (typeof this.order.accessories.blackRimSet === 'undefined') {
        return null;
      }
      const blackRimSet = this.$kyburz.accessories.blackRimSets.find((s) => s.value === this.order.accessories.blackRimSet);
      return {
        name: blackRimSet.name,
        price: blackRimSet.price,
      };
    },
    caneholder() {
      if (typeof this.order.accessories.caneholder === 'undefined') {
        return null;
      }
      const caneholder = this.$kyburz.accessories.caneholders.find((gh) => gh.value === this.order.accessories.caneholder);
      return {
        name: caneholder.name,
        price: caneholder.price,
      };
    },
    seat() {
      if (typeof this.order.accessories.seat === 'undefined') {
        return null;
      }
      const seat = this.$kyburz.accessories.seats.find((s) => s.value === this.order.accessories.seat);
      return {
        name: seat.name,
        price: seat.price,
      };
    },
    charger() {
      if (typeof this.order.accessories.charger === 'undefined') {
        return null;
      }
      const charger = this.$kyburz.accessories.chargers.find((s) => s.value === this.order.accessories.charger);
      return {
        name: charger.name,
        price: charger.price,
      };
    },
    bucketSeat() {
      if (typeof this.order.accessories.bucketSeat === 'undefined') {
        return null;
      }
      const bucketSeat = this.$kyburz.accessories.bucketSeats.find((s) => s.value === this.order.accessories.bucketSeat);
      return {
        name: bucketSeat.name,
        price: bucketSeat.price,
      };
    },
    tire() {
      if (typeof this.order.accessories.tire === 'undefined') {
        return null;
      }
      const tire = this.$kyburz.accessories.tires.find((s) => s.value === this.order.accessories.tire);
      return {
        name: tire.name,
        price: tire.price,
      };
    },
    schukoAdapter() {
      if (typeof this.order.accessories.schukoAdapter === 'undefined') {
        return null;
      }
      const schukoAdapter = this.$kyburz.accessories.schukoAdapters.find((s) => s.value === this.order.accessories.schukoAdapter);
      return {
        name: schukoAdapter.name,
        price: schukoAdapter.price,
      };
    },
    adapterCable() {
      if (typeof this.order.accessories.adapterCable === 'undefined') {
        return null;
      }
      const adapterCable = this.$kyburz.accessories.adapterCables.find((s) => s.value === this.order.accessories.adapterCable);
      return {
        name: adapterCable.name,
        price: adapterCable.price,
      };
    },
    weatherProtection() {
      if (typeof this.order.accessories.weatherProtection === 'undefined') {
        return null;
      }
      const weatherProtection = this.$kyburz.accessories.weatherProtections.find((s) => s.value === this.order.accessories.weatherProtection);
      return {
        name: weatherProtection.name,
        price: weatherProtection.price,
      };
    },
    suitcase() {
      if (typeof this.order.accessories.suitcase === 'undefined') {
        return null;
      }
      const suitcase = this.$kyburz.accessories.suitcases.find((s) => s.value === this.order.accessories.suitcase);
      return {
        name: suitcase.name,
        price: suitcase.price,
      };
    },
    fleetManagement() {
      if (typeof this.order.accessories.fleetManagement === 'undefined') {
        return null;
      }
      const fleetManagement = this.$kyburz.accessories.fleetManagements.find((s) => s.value === this.order.accessories.fleetManagement);
      return {
        name: fleetManagement.name,
        price: fleetManagement.price,
      };
    },
    differential() {
      if (typeof this.order.accessories.differential === 'undefined') {
        return null;
      }
      const differential = this.$kyburz.accessories.differentials.find((s) => s.value === this.order.accessories.differential);
      return {
        name: differential.name,
        price: differential.price,
      };
    },
    handling() {
      if (typeof this.order.accessories.handling === 'undefined') {
        return null;
      }
      const handling = this.$kyburz.accessories.handlings.find((s) => s.value === this.order.accessories.handling);
      return {
        name: handling.name,
        price: handling.price,
      };
    },
    doorOpenHolder() {
      if (typeof this.order.accessories.doorOpenHolder === 'undefined') {
        return null;
      }
      const doorOpenHolder = this.$kyburz.accessories.doorOpenHolders.find((s) => s.value === this.order.accessories.doorOpenHolder);
      return {
        name: doorOpenHolder.name,
        price: doorOpenHolder.price,
      };
    },
    naviPlugSocket() {
      if (typeof this.order.accessories.naviPlugSocket === 'undefined') {
        return null;
      }
      const naviPlugSocket = this.$kyburz.accessories.naviPlugSockets.find((s) => s.value === this.order.accessories.naviPlugSocket);
      return {
        name: naviPlugSocket.name,
        price: naviPlugSocket.price,
      };
    },
    usbPlugSocket() {
      if (typeof this.order.accessories.usbPlugSocket === 'undefined') {
        return null;
      }
      const usbPlugSocket = this.$kyburz.accessories.usbPlugSockets.find((s) => s.value === this.order.accessories.usbPlugSocket);
      return {
        name: usbPlugSocket.name,
        price: usbPlugSocket.price,
      };
    },
    towCoupling() {
      if (typeof this.order.accessories.towCoupling === 'undefined') {
        return null;
      }
      const towCoupling = this.$kyburz.accessories.towCouplings.find((s) => s.value === this.order.accessories.towCoupling);
      return {
        name: towCoupling.name,
        price: towCoupling.price,
      };
    },
    sunVisor() {
      if (typeof this.order.accessories.sunVisor === 'undefined') {
        return null;
      }
      const sunVisor = this.$kyburz.accessories.sunVisors.find((s) => s.value === this.order.accessories.sunVisor);
      return {
        name: sunVisor.name,
        price: sunVisor.price,
      };
    },
    headrest() {
      if (typeof this.order.accessories.headrest === 'undefined') {
        return null;
      }
      const headrest = this.$kyburz.accessories.headrests.find((s) => s.value === this.order.accessories.headrest);
      return {
        name: headrest.name,
        price: headrest.price,
      };
    },
    rollator() {
      if (typeof this.order.accessories.rollator === 'undefined') {
        return null;
      }
      const rollator = this.$kyburz.accessories.rollators.find((s) => s.value === this.order.accessories.rollator);
      return {
        name: rollator.name,
        price: rollator.price,
      };
    },
    naviDevice() {
      if (typeof this.order.accessories.naviDevice === 'undefined') {
        return null;
      }
      const naviDevice = this.$kyburz.accessories.naviDevices.find((s) => s.value === this.order.accessories.naviDevice);
      return {
        name: naviDevice.name,
        price: naviDevice.price,
      };
    },
    trailer() {
      if (typeof this.order.accessories.trailer === 'undefined') {
        return null;
      }
      const trailer = this.$kyburz.accessories.trailers.find((s) => s.value === this.order.accessories.trailer);
      return {
        name: trailer.name,
        price: trailer.price,
      };
    },
    rainProtection() {
      if (typeof this.order.accessories.rainProtection === 'undefined') {
        return null;
      }
      const rainProtection = this.$kyburz.accessories.rainProtections.find((s) => s.value === this.order.accessories.rainProtection);
      return {
        name: rainProtection.name,
        price: rainProtection.price,
      };
    },
    springSeat() {
      if (typeof this.order.accessories.springSeat === 'undefined') {
        return null;
      }
      const springSeat = this.$kyburz.accessories.springSeats.find((s) => s.value === this.order.accessories.springSeat);
      return {
        name: springSeat.name,
        price: springSeat.price,
      };
    },
    rekuModule() {
      if (typeof this.order.accessories.rekuModule === 'undefined') {
        return null;
      }
      const rekuModule = this.$kyburz.accessories.rekuModules.find((s) => s.value === this.order.accessories.rekuModule);
      return {
        name: rekuModule.name,
        price: rekuModule.price,
      };
    },
    keylessGo() {
      if (typeof this.order.accessories.keylessGo === 'undefined') {
        return null;
      }
      const keylessGo = this.$kyburz.accessories.keylessGos.find((s) => s.value === this.order.accessories.keylessGo);
      return {
        name: keylessGo.name,
        price: keylessGo.price,
      };
    },
    winterWheel() {
      if (typeof this.order.accessories.winterWheel === 'undefined') {
        return null;
      }
      const winterWheel = this.$kyburz.accessories.winterWheels.find((s) => s.value === this.order.accessories.winterWheel);
      return {
        name: winterWheel.name,
        price: winterWheel.price,
      };
    },
    radio() {
      if (typeof this.order.accessories.radio === 'undefined') {
        return null;
      }
      const radio = this.$kyburz.accessories.radios.find((s) => s.value === this.order.accessories.radio);
      return {
        name: radio.name,
        price: radio.price,
      };
    },
    heatedGrip() {
      if (typeof this.order.accessories.heatedGrip === 'undefined') {
        return null;
      }
      const heatedGrip = this.$kyburz.accessories.heatedGrips.find((s) => s.value === this.order.accessories.heatedGrip);
      return {
        name: heatedGrip.name,
        price: heatedGrip.price,
      };
    },
    heatedFloor() {
      if (typeof this.order.accessories.heatedFloor === 'undefined') {
        return null;
      }
      const heatedFloor = this.$kyburz.accessories.heatedFloors.find((s) => s.value === this.order.accessories.heatedFloor);
      return {
        name: heatedFloor.name,
        price: heatedFloor.price,
      };
    },
    distressButton() {
      if (typeof this.order.accessories.distressButton === 'undefined') {
        return null;
      }
      const distressButton = this.$kyburz.accessories.distressButtons.find((s) => s.value === this.order.accessories.distressButton);
      return {
        name: distressButton.name,
        price: distressButton.price,
      };
    },
    accessoryRoof() {
      if (typeof this.order.accessories.accessoryRoof === 'undefined') {
        return null;
      }
      const accessoryRoof = this.$kyburz.accessories.accessoryRoofs.find((s) => s.value === this.order.accessories.accessoryRoof);
      return {
        name: accessoryRoof.name,
        price: accessoryRoof.price,
      };
    },
    singleInspection() {
      if (typeof this.order.accessories.singleInspection === 'undefined') {
        return null;
      }
      const singleInspection = this.$kyburz.accessories.singleInspections.find((s) => s.value === this.order.accessories.singleInspection);
      return {
        name: singleInspection.name,
        price: singleInspection.price,
      };
    },
    delivery() {
      return this.$kyburz.deliveries.find((del) => del.value === this.order.delivery);
    },
    instruction() {
      return this.$kyburz.instructions.find((ins) => ins.value === this.order.instruction);
    },
    totalVehicle() {
      return this.$store.getters.vehicleTotal;
    },
  },
  mounted() {
    this.$store.commit('UNSET_STEP_COMPLETE', 6);
    this.takeVehicleImage();

    this.$track(this.$options.name, null, null, this.selectedType, this.selectedVehicle);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
  methods: {
    setCountVehicles(count) {
      this.$store.commit('SELECT_COUNT_VEHICLES', Number(count));
    },
    takeVehicleImage() {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const previewImageDiv = document.querySelector('#vehicle-preview');
      setTimeout(() => { // eslint-disable-line
        if (isSafari) {
          html2canvas(previewImageDiv)
            .then((canvas) => {
              const imageDataUrl = canvas.toDataURL('image/png');
              this.$store.commit('SET_PREVIEW_URL', imageDataUrl);
              this.$store.commit('SET_STEP_COMPLETE', 6);
            })
            .catch((error) => {
              if (this.$isDev) {
                console.error(error); // eslint-disable-line
              } else {
                Sentry.captureException(error);
              }
            });
        } else {
          domtoimage.toPng(previewImageDiv)
            .then((imageDataUrl) => {
              this.$store.commit('SET_PREVIEW_URL', imageDataUrl);
              this.$store.commit('SET_STEP_COMPLETE', 6);
            })
            .catch((error) => {
              if (this.$isDev) {
                console.error(error); // eslint-disable-line
              } else {
                Sentry.captureException(error);
              }
            });
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.summary {
  width: 760px;

  &__container {
    max-width: 1255px;
  }

  tr {
    td {
      font-size: 16px !important;
      font-weight: 400;
      height: auto !important;
      vertical-align: top;
      padding: .25rem .5rem !important;

      b {
        font-weight: 600;
      }

      &.summary {
        font-size: 110%;
        font-weight: 600;
      }

      &:first-of-type {
        padding-right: 2rem;
      }

      &:last-of-type {
        color: #1b49a6;
        text-align: right;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    &.summary {

      td {
        border-top: 2px solid #999999 !important;
        font-size: 110%;
        font-weight: 400;
      }
    }

    &.total-vehicle {
      td {
        border-bottom: 2px solid #999999 !important;
      }
    }
  }
}

.delivery-remarks {
  font-size: 110%;
  font-weight: 300;
}

@media (max-width: 1904px) {
  .summary {

    tr {
      td {
        font-size: 90%;
      }
    }
  }
}

@media (max-width: 1024px) {

  .summary {
    min-width: 0;
    max-width: 80%;
    margin: 0 auto;

    tr {
      td {
        font-size: 80%;
        padding: 4px 8px;
        height: auto;

        &:first-of-type {
          b {
            white-space: nowrap;
          }

        }
      }

      &.summary {
        td {
          font-size: 100%;
        }
      }
    }
  }

  .delivery-remarks {
    font-size: 80%;
  }
}

@media (max-width: 600px) {

  .summary.v-data-table {
    max-width: 100%;

    tr {
      td {
        font-size: 80% !important;
        padding: 4px 8px !important;
        height: auto !important;
        display: block;
        text-align: left;

        &:first-of-type {
          border-bottom: 1px solid transparent !important;
        }
      }

      &.summary {
        td {
          font-size: 90% !important;
        }
      }
    }
  }

  .delivery-remarks {
    font-size: 70%;
  }
}

</style>
