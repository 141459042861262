<template>
  <v-container
    class="pt-0 pt-md-16"
  >
    <v-row
      v-if="isErodBauseminar"
    >
      <v-col
        cols="12"
        class="d-flex flex-column justify-end align-center"
      >
        <v-alert
          type="info"
          outlined
          color="primary"
        >
          {{ $t('content.erod-bauseminar-hint') }}
        </v-alert>
        <v-btn
          color="primary"
          tile
          outlined
          :small="$vuetify.breakpoint.xsOnly"
          :x-large="$vuetify.breakpoint.lgAndUp"
          :aria-label="$t('gui.next')"
          @click="$router.push({ name: 'step-6' })"
        >
          {{ $t('gui.next') }}
          <v-icon right>
            mdi-play
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="d-flex justify-center align-center"
      >
        <v-card
          class="card"
          :disabled="isErodBauseminar"
        >
          <v-img
            src="img/delivery.jpg"
            alt="delivery"
            aspect-ratio="1.5"
          />
          <v-card-title
            class="card__title pb-0 pb-sm-3"
          >
            {{ $t('content.delivery') }}
          </v-card-title>
          <v-card-text
            class="card__text"
          >
            <v-radio-group v-model="deliverySelected">
              <v-radio
                v-for="(delivery, index) in $kyburz.deliveries"
                :key="index"
                :value="delivery.value"
                color="primary"
              >
                <template v-slot:label>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="`${delivery.name[$i18n.locale]} ${$options.filters.toCurrencyRemark(delivery.price)}`" />
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          class="card"
          :disabled="isErodBauseminar"
        >
          <v-img
            src="img/instruction.jpg"
            alt="instruction"
            aspect-ratio="1.5"
          />
          <v-card-title
            class="card__title pb-0 pb-sm-3"
          >
            {{ $t('content.instruction') }}
          </v-card-title>
          <v-card-text
            class="card__text"
          >
            <v-radio-group v-model="instructionSelected">
              <v-radio
                v-for="(instruction, index) in $kyburz.instructions"
                :key="index"
                :value="instruction.value"
                color="primary"
              >
                <template v-slot:label>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="`${instruction.name[$i18n.locale]} ${$options.filters.toCurrencyRemark(instruction.price)}`" />
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Delivery', // step-5
  data() {
    return {
      optionChanged: false,
    };
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
      selectedVersion: (state) => state.order.version,
    }),
    deliverySelected: {
      get() {
        return this.$store.state.order.delivery;
      },
      set(newValue) {
        this.$store.commit('SELECT_DELIVERY', newValue);
        this.optionChanged = true;
      },
    },
    instructionSelected: {
      get() {
        return this.$store.state.order.instruction;
      },
      set(newValue) {
        this.$store.commit('SELECT_INSTRUCTION', newValue);
        this.optionChanged = true;
      },
    },
    cardImageHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 300;
      }
      return 250;
    },
    isErodBauseminar() {
      if (this.selectedVersion === 'eRod-bauseminar') {
        return true;
      }
      return false;
    },
  },
  watch: {
    optionChanged(newValue, oldValue) {
      if (oldValue === false) {
        this.$track(this.$options.name, 'delivery-changed', null, this.selectedType, this.selectedVehicle);
      }
    },
  },
  mounted() {
    if (this.selectedVersion === 'eRod-bauseminar') {
      this.$store.commit('SET_STEP_COMPLETE', 5);
    }

    this.$track(this.$options.name, null, null, this.selectedType, this.selectedVehicle);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
};
</script>
