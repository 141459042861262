<template>
  <div
    ref="selector-overlay"
    class="selector-overlay"
  >
    <v-btn
      fab
      fixed
      right
      top
      elevation="10"
      color="primary"
      class="close-button"
      @click="close"
    >
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>

    <v-container
      :fluid="$vuetify.breakpoint.mdAndUp && type !== 'foiling'"
      class="configurator-container d-flex justify-center align-start"
      :fill-height="$vuetify.breakpoint.smAndUp"
    >
      <v-row
        class="configurator-container__row justify-center align-center fill-height"
        style="width: 100%;"
      >
        <v-col
          v-for="(element, index) in availableElements"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            light
            style="margin: 0 auto;height:100%;"
            class="card"
            :class="{
              'card--hover': isCardClickable
            }"
          >
            <v-img
              :aspect-ratio="(type === 'colors') ? 1.5 : 1"
              :src="element.selectImageUrl"
              class="card__image-clickable"
              @click="selectElement(type, element.id)"
            />

            <v-card-title
              class="card__title card__title--minheight pb-1 pb-sm-3"
              :class="{ 'card__title--ipadminheight': (selectedType === 'DX2' && type === 'back') }"
            >
              {{ element.name[$i18n.locale] }}
            </v-card-title>
            <v-card-text
              class="card__text"
            >
              <p class="mb-1">
                {{ element.price | toCurrency }}
              </p>

              <p
                v-if="$debug"
                class="mb-1"
              >
                <code>{{ element.artNr }}</code>
              </p>
            </v-card-text>

            <v-card-actions>
              <v-select
                v-if="(selectedType === 'DXC' && type === 'colors' && element.id == 99)"
                ref="colorRal"
                v-model="colorRal"
                :items="ralColors"
                outlined
                dense
                hide-details
                label="RAL Code"
                :rules="[rules.required]"
                class="ral-color"
              >
                <template v-slot:selection="{ item }">
                  {{ item }}&nbsp;
                  <div
                    class="ral-color__dot"
                    :style="`background-color:${getHexForRal(item)}`"
                  />
                </template>
                <template v-slot:item="{ item }">
                  {{ item }}&nbsp;<div
                    class="ral-color__dot"
                    :style="`background-color:${getHexForRal(item)}`"
                  />
                </template>
              </v-select>
              <v-spacer />
              <v-btn
                v-if="selectedElement != element.id"
                color="primary"
                tile
                outlined
                large
                @click="selectElement(type, element.id)"
              >
                {{ $t('gui.select') }}
              </v-btn>
              <v-btn
                v-else
                tile
                color="primary"
                large
                @click="selectElement(type, element.id)"
              >
                <v-icon class="mr-2">
                  mdi-check-bold
                </v-icon>{{ $t('gui.selected') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ConfiguratorSelector',
  props: {
    type: {
      type: String,
      default: null,
    },
    elements: {
      type: Array,
      default: () => [],
    },
    selectedElement: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    color: null,
    configuratorContainer: null,
    configuratorContainerRow: null,
    rules: {
      required: null,
    },
    formHasErrors: false,
  }),
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
      selectedColor: (state) => state.order.color,
      selectedDoor: (state) => state.order.door,
    }),
    colorRal: {
      get() {
        return this.$store.state.order.colorRal;
      },
      set(newValue) {
        this.$store.commit('SELECT_COLOR_RAL', newValue);
      },
    },
    ralColors() {
      return Object.keys(this.$kyburz.ralColors);
    },
    isCardClickable() {
      if (this.selectedType === 'DXC' && this.type === 'colors') {
        return false;
      }
      return true;
    },
    availableElements() {
      const availableElements = [];
      this.elements.map((element) => { // eslint-disable-line
        const result = element[this.selectedVehicle];
        if (result !== undefined) {
          result.id = element.id;
          result.name = element.name;
          if (this.selectedType === 'eRod') {
            result.colorCode = element.colorCode;
          }
          availableElements.push(result);
        }
      });

      // special case colored doors PLUS 2.3
      if ((this.selectedVehicle === 'PLUS' || this.selectedVehicle === 'PLUS_II') && this.type === 'door') {
        const coloredDoorElements = [];
        availableElements.forEach((element) => {
          coloredDoorElements.push({
            id: element.id,
            name: element.name,
            price: element.price,
            artNr: element.artNr,
            selectImageUrl: element.selectImageUrl[this.selectedColor],
          });
        });
        return coloredDoorElements;
      }

      // special case color with or without doors PLUS 2.3
      if ((this.selectedVehicle === 'PLUS' || this.selectedVehicle === 'PLUS_II') && this.type === 'colors') {
        const coloredDoorElements = [];
        availableElements.forEach((element) => {
          coloredDoorElements.push({
            id: element.id,
            name: element.name,
            price: element.price,
            artNr: element.artNr,
            selectImageUrl: element.selectImageUrl[this.selectedDoor],
          });
        });
        return coloredDoorElements;
      }

      return availableElements;
    },
  },
  created() {
    // initialize the form rules when translations are ready
    this.initRules();
  },
  mounted() {
    // console.log('this.elements', this.elements);
    const appDiv = document.getElementsByClassName('v-application--wrap')[0];
    appDiv.after(this.$refs['selector-overlay']);
    this.configuratorContainer = document.getElementsByClassName('configurator-container')[0]; // eslint-disable-line
    this.configuratorContainer.addEventListener('click', this.overlayEventHandler, false);

    this.configuratorContainerRow = document.getElementsByClassName('configurator-container__row')[0]; // eslint-disable-line
    this.configuratorContainerRow.addEventListener('click', this.overlayEventHandler, false);
  },
  beforeDestroy() {
    this.configuratorContainer.removeEventListener('click', this.overlayEventHandler);
    this.configuratorContainerRow.removeEventListener('click', this.overlayEventHandler);
  },
  methods: {
    close() {
      this.$emit('closeSelector');
    },
    selectElement(type, elementId) {
      this.formHasErrors = false;
      if (type === 'colors' && elementId === 99) {
        this.validateForm();

        // console.log('this.formHasErrors', this.formHasErrors);
        if (!this.formHasErrors) {
          this.$emit('selectElement', {
            type,
            elementId,
          });
          this.mixinSendScrollUpCommandToParentPage();
        }
      } else if (type === 'colors' && elementId !== 99) {
        this.$emit('selectElement', {
          type,
          elementId,
        });
        this.mixinSendScrollUpCommandToParentPage();

        setTimeout(() => {
          this.colorRal = null;
        }, 1000);
      } else {
        this.$emit('selectElement', {
          type,
          elementId,
        });
        this.mixinSendScrollUpCommandToParentPage();
      }
    },
    overlayEventHandler(event) {
      if (event.currentTarget !== event.target) {
        return;
      }
      this.close();
    },
    initRules() {
      this.rules.required = (value) => !!value || this.$t('form.required');
    },
    validateForm() {
      const validation = this.$refs.colorRal[0].validate(true);
      if (validation === false) {
        this.formHasErrors = true;
      }
    },
    getHexForRal(ralCode) {
      return this.$kyburz.ralColors[ralCode];
    },
  },
};
</script>

<style lang="scss">
.selector-overlay {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 0;
  overflow: hidden;

  .v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.v-application .close-button {
  background-color: white !important;
  color: #1b49a6 !important;

  &:hover {
    background-color: #1b49a6 !important;
    color: white !important;
  }
}

.ral-color {
  max-width: 160px;
  background-color: #ffffff;

  &__dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 16px;
  }
}
</style>
