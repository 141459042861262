<template>
  <div class="number-input">
    <div class="number-input__title">
      {{ label }}
    </div>
    <div class="number-input__form">
      <v-btn
        fab
        x-small
        color="primary"
        aria-label="decrease"
        @click="decrease()"
      >
        <v-icon>
          mdi-minus
        </v-icon>
      </v-btn>

      <v-text-field
        v-model="localCount"
        class="number-input__text-field"
        outlined
      />

      <v-btn
        fab
        x-small
        color="primary"
        aria-label="increase"
        @click="increase()"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    count: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Label',
    },
  },
  data: () => ({
    localCount: 1,
    min: 1,
  }),
  watch: {
    localCount() {
      this.emitCount();
    },
  },
  created() {
    this.localCount = this.count;
  },
  methods: {
    emitCount() {
      this.$emit('setcount', Number(this.localCount));
    },
    increase() {
      this.localCount += 1;
    },
    decrease() {
      if (this.localCount > this.min) {
        this.localCount -= 1;
      }
    },
  },
};
</script>

<style lang="scss">
.number-input {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    white-space: nowrap;
    margin-right: 2rem;
  }

  &__form {
    display: flex;
  }

  &__text-field {
    margin: 0 1rem !important;

    .v-text-field__details {
      display: none;
    }
  }

  fieldset {
    padding-left: 0 !important;
  }

  .v-input__control {
    height: 32px;
  }

  .v-input__slot {
    margin: 0;

    input {
      width: 32px;
      text-align: center;
    }
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 32px;
  }
}

</style>
