<template>
  <transition name="fade">
    <v-form
      v-if="formResponse === null || formResponse === false"
      ref="form"
      novalidate="true"
      style="max-width:1180px;margin:0 auto;"
      @submit.prevent="processForm"
    >
      <v-container class="termination pt-0 pt-md-16">
        <v-row>
          <v-col>
            <h2
              style="min-height:0;"
              class="content-title mb-1 mb-sm-2"
            >
              {{ $t('form.iwant') }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <input
            ref="task"
            type="hidden"
            name="task"
            :value="localPerson.task"
          >
          <v-col class="pt-0">
            <v-radio-group
              v-model="localPerson.task"
              @change="changeTask()"
            >
              <v-radio
                :label="$t('form.iwant-order')"
                value="order"
                color="primary"
                large
              />
              <v-radio
                :label="$t('form.iwant-offer')"
                value="offer"
                color="primary"
              />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
          >
            <h2
              class="content-title mt-sm-6 mb-1 mb-sm-2"
              style="min-height:0;"
            >
              {{ $t('form.please-personal-information') }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-radio-group
              ref="gender"
              v-model="localPerson.gender"
              :label="$t('form.gender') + ' *'"
              :row="true"
              :rules="[rules.genderSelected]"
            >
              <v-radio
                v-for="(genderOption, index) in genderOptions"
                :key="index"
                :label="$t('form.' + genderOption)"
                :value="genderOption"
                color="primary"
              />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row
          class="mb-md-2 mb-lg-4"
        >
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="firstname"
              v-model="localPerson.firstname"
              :label="$t('form.firstname') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="lastname"
              v-model="localPerson.lastname"
              :label="$t('form.lastname') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
        </v-row>

        <v-row
          class="mb-md-2 mb-lg-4"
        >
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="dateofbirth"
              v-model="localPerson.dateofbirth"
              :label="$t('form.dateofbirth') + ' (' + $t('form.optional') + ')'"
              :outlined="formOutlined"
              :rules="[rules.validDate, rules.notrequired]"
              :hint="$t('form.dateofbirthformat')"
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row
          class="mb-md-2 mb-lg-4"
        >
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="company"
              v-model="localPerson.company"
              :label="$t('form.company') + ' (' + $t('form.optional') + ')'"
              :outlined="formOutlined"
              :rules="[rules.notrequired]"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0 optional-row"
          >
            <v-text-field
              ref="street"
              v-model="localPerson.street"
              :label="$t('form.street') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
        </v-row>

        <v-row
          class="mb-md-2 mb-lg-4"
        >
          <v-col
            cols="4"
            sm="2"
            class="pt-0 pb-0 optional-row"
          >
            <v-text-field
              ref="zip"
              v-model="localPerson.zip"
              :label="$t('form.zip') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
          <v-col
            cols="8"
            sm="4"
            class="pt-0 pb-0 optional-row"
          >
            <v-text-field
              ref="city"
              v-model="localPerson.city"
              :label="$t('form.city') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0 optional-row"
          >
            <v-text-field
              ref="country"
              v-model="localPerson.country"
              :label="$t('form.country') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
        </v-row>

        <v-row
          class="mb-md-2 mb-lg-4"
        >
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="email"
              v-model="localPerson.email"
              type="email"
              :label="$t('form.email') + ' *'"
              :rules="[rules.required, rules.email]"
              :outlined="formOutlined"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              ref="phone"
              v-model="localPerson.phone"
              :label="$t('form.phone') + ' *'"
              :rules="[rules.required]"
              :outlined="formOutlined"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-textarea
              ref="message"
              v-model="localPerson.message"
              :label="$t('form.message') + ' (' + $t('form.optional') + ')'"
              auto-grow
              rows="1"
              :outlined="formOutlined"
              :rules="[rules.notrequired]"
            />
          </v-col>
        </v-row>

        <v-row
          :class="{ 'hidden': localPerson.task === 'offer' }"
          class="optional-row"
        >
          <v-col
            cols="12"
            class="accept agb pt-0 pb-0 d-flex align-center"
          >
            <v-checkbox
              ref="agb"
              v-model="localPerson.agb"
              color="primary"
              :rules="(localPerson.task === 'order') ? [rules.acceptAGB] : [rules.notrequired]"
              class="mt-0"
            >
              <template v-slot:label>
                <div>
                  {{ $t('form.agb-text-1') }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="$t('form.agb-link')"
                        rel="noopener"
                        @click.stop
                        v-on="on"
                      >{{ $t('form.agb-label') }}</a>
                    </template>
                    <v-icon color="white">
                      mdi-arrow-top-right
                    </v-icon>
                  </v-tooltip>
                  {{ $t('form.agb-text-2') }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-6 mt-sm-2">
          <v-col
            cols="12"
            class="accept datenschutz pt-0 pb-0 d-flex align-center"
          >
            <v-checkbox
              id="datenschutz"
              ref="datenschutz"
              v-model="localPerson.datenschutz"
              color="primary"
              :rules="[rules.acceptDatenschutz]"
            >
              <template v-slot:label>
                <div>
                  {{ $t('form.data-protection-text-1') }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="$t('form.data-protection-link')"
                        rel="noopener"
                        @click.stop
                        v-on="on"
                      >{{ $t('form.data-protection-label') }}</a>
                    </template>
                    <v-icon color="white">
                      mdi-arrow-top-right
                    </v-icon>
                  </v-tooltip>
                  {{ $t('form.data-protection-text-2') }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          v-if="formHasErrors && formMessage !== ''"
        >
          <v-col>
            <v-alert
              type="error"
            >
              {{ formMessage }}
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            order="2"
            order-sm="1"
          >
            <v-btn
              color="#999"
              tile
              outlined
              :small="$vuetify.breakpoint.smAndDown"
              @click="resetConfigurator()"
            >
              {{ $t('gui.reset') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            order="1"
            order-sm="2"
            class="d-flex justify-end align-center"
          >
            <v-progress-circular
              v-if="formSending"
              indeterminate
              color="primary"
              class="mr-2"
            />
            <v-btn
              v-if="localPerson.task === 'order'"
              color="primary"
              tile
              outlined
              :x-large="$vuetify.breakpoint.lgAndUp"
              type="submit"
            >
              {{ $t('form.order') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              tile
              outlined
              :x-large="$vuetify.breakpoint.lgAndUp"
              type="submit"
            >
              {{ $t('form.offer') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-container
      v-else
      fill-height
      class="pt-0 pt-md-16"
    >
      <v-row
        :class="{ 'd-flex flex-column align-center' : $vuetify.breakpoint.mdAndDown }"
      >
        <v-col
          cols="12"
          lg="6"
        >
          <vehicle-preview />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pl-10 pl-md-0"
        >
          <h1
            v-if="localPerson.task === 'order'"
            class="card__title mb-2 mb-sm-4"
          >
            {{ $t('form-thanks.thanks') }}
          </h1>
          <h1
            v-else
            class="card__title mb-2 mb-sm-4"
          >
            {{ $t('form-thanks.thanks-offer') }}
          </h1>

          <!-- eslint-disable vue/no-v-html -->
          <p
            class="body-1 mb-10"
            v-html="$t('form-thanks.text')"
          />
          <!-- eslint-enable vue/no-v-html -->

          <v-btn
            v-if="viewMode === 'standalone'"
            tile
            outlined
            color="primary"
            class="mr-2 mb-2"
            :x-large="$vuetify.breakpoint.lgAndUp"
            @click.prevent="leaveConfigurator()"
          >
            <v-icon left>
              mdi-close
            </v-icon> {{ $t('form-thanks.leave') }}
          </v-btn>
          <br>
          <v-btn
            tile
            outlined
            color="primary"
            :x-large="$vuetify.breakpoint.lgAndUp"
            @click.prevent="resetOrder()"
          >
            <v-icon left>
              mdi-reload
            </v-icon> {{ $t('form-thanks.other-vehicle') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>

<script>
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { mapState } from 'vuex';
import validator from 'email-validator';
import VehiclePreview from '../components/VehiclePreview.vue';

export default {
  name: 'Termination', // step-7
  components: {
    VehiclePreview,
  },
  data: () => ({
    localPerson: {},
    formResponse: null,
    formSending: false,
    formHasErrors: false,
    genderOptions: [
      'f',
      'm',
    ],
    rules: {
      required: null,
      email: null,
      notrequired: null,
      oneSelected: null,
      acceptAGB: null,
      acceptDatenschutz: null,
      validDate: null,
    },
    formOutlined: true,
    formMessage: '',
  }),
  computed: {
    ...mapState({
      viewMode: (state) => state.viewMode,
      person: (state) => state.person,
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
  },
  watch: {
    formHasErrors(newValue) {
      if (newValue === true) {
        this.scrollToError();
      }
    },
    formResponse(newValue) {
      if (newValue === false) {
        this.formHasErrors = true;
        this.formMessage = this.$t('form-error.send-failure');
      } else {
        this.formHasErrors = false;
        this.formMessage = '';
        this.mixinSendScrollUpCommandToParentPage();
      }
    },
  },
  created() {
    this.localPerson = this.person;

    // initialize the form rules when translations are ready
    this.initRules();
  },
  mounted() {
    this.$track(this.$options.name, null, null, this.selectedType, this.selectedVehicle);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
  methods: {
    changeTask() {
      this.$store.commit('SET_PERSON_ORDER_TASK', this.localPerson.task);
    },
    setTask(task) {
      this.$store.commit('SET_PERSON_ORDER_TASK', task);
    },
    initRules() {
      this.rules.required = (value) => !!value || this.$t('form.required');
      this.rules.email = (value) => {
        const emailValid = validator.validate(value);
        return emailValid || this.$t('form-error.email-invalid');
      };
      this.rules.notrequired = () => true;
      this.rules.genderSelected = (value) => ((value) ? true : this.$t('form-error.gender'));
      this.rules.acceptAGB = (value) => ((value) ? true : this.$t('form-error.agb'));
      this.rules.acceptDatenschutz = (value) => ((value) ? true : this.$t('form-error.datenschutz'));
      this.rules.validDate = (value) => {
        // field ist optional. so if it's empty, it's valid
        if (value === null || value === '') {
          return true;
        }
        const dateValid = this.validateDateFormat(value);
        return dateValid || this.$t('form-error.dateofbirthformat');
      };
    },
    validateDateFormat(date) {
      // length must be 10 characters
      if (date.length !== 10) {
        return false;
      }

      const dateParts = date.split('.');
      if (
        dateParts.length !== 3
        || Number(dateParts[0]) < 1
        || Number(dateParts[0]) > 31
        || Number(dateParts[1]) < 1
        || Number(dateParts[1]) > 12
        || Number(dateParts[2]) < 1900
        || Number(dateParts[2]) > 9999
      ) {
        return false;
      }
      return true;
    },
    processForm() {
      this.savePerson();
      this.validateForm();

      if (!this.formHasErrors) {
        this.formResponse = null;
        this.formSending = true;
        this.send();
      }
    },
    validateForm() {
      this.formHasErrors = false;

      Object.keys(this.localPerson).forEach((formElement) => {
        if (formElement !== 'task' && formElement !== 'lang') {
          const validation = this.$refs[formElement].validate(true);

          if (validation === false) {
            this.formHasErrors = true;
          }
        }
      });
    },
    send() {
      const { orderContent } = this.$store.getters;

      const content = this.translateOrder(orderContent);

      const data = {
        data: this.$store.getters.orderData,
        content,
      };

      this.postData(data)
        .then((result) => {
          if (result === true) {
            this.formResponse = true;
            this.$store.commit('SET_STEP_COMPLETE', 6);

            const { countVehicles } = this.$store.state.order;

            if (this.localPerson.task === 'offer') {
              this.$track(this.$options.name, 'Offer', countVehicles, this.selectedType, this.selectedVehicle);
            }

            if (this.localPerson.task === 'order') {
              this.$track(this.$options.name, 'Order', countVehicles, this.selectedType, this.selectedVehicle);
            }
          } else {
            this.formResponse = false;
          }
        })
        .catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
          this.$track(this.$options.name, 'Error', JSON.stringify(error));
          this.formResponse = false;
        });
    },
    postData(data) {
      return new Promise((resolve, reject) => { // eslint-disable-line
        let url = '/api/postform';
        if (this.$isDev) {
          url = 'http://localhost/api/postform';
        }

        try {
          axios({
            url,
            method: 'post',
            data,
          }).then((response) => {
            resolve(Boolean(response.data));
          }).catch((error) => {
            if (this.$isDev) {
              console.error(error); // eslint-disable-line
            } else {
              Sentry.captureException(error);
            }
            reject(error);
          });
        } catch (error) {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
          this.$track(this.$options.name, 'Error', JSON.stringify(error));
        }
      });
    },
    savePerson() {
      this.$store.commit('SET_PERSON', this.localPerson);
    },
    resetConfigurator() {
      this.$store.commit('RESET_CONFIGURATOR');
      this.$router.push({ name: 'step-1' }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    leaveConfigurator() {
      this.clearLocalStorage();
      window.location = this.$t('gui.logo-link');
    },
    clearLocalStorage() {
      localStorage.removeItem('kyburz-configurator-state');
      localStorage.removeItem('kyburz-configurator-version');
    },
    resetOrder() {
      this.$store.commit('RESET_ORDER');
      this.$store.commit('RESET_STEPS_COMPLETE');
      this.$router.push({ name: 'step-1' }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    translateOrder(orderData) {
      // translate person
      const { person } = orderData;
      const personTranslated = {};
      Object.keys(person).forEach((key) => {
        if (key !== 'agb' && key !== 'datenschutz') {
          if (key === 'gender') {
            personTranslated[key] = {
              key: this.$t(`form.${key}`),
              value: this.$t(`form.${person[key]}`),
            };
          } else {
            personTranslated[key] = {
              key: this.$t(`form.${key}`),
              value: person[key],
            };
          }
        }
      });

      let introduction = (this.localPerson.task === 'order') ? this.$t('mailtext.introduction') : this.$t('mailtext.introduction-offer');
      if (this.selectedType === 'eRod') {
        introduction = (this.localPerson.task === 'order') ? this.$t('mailtext.introduction-eRod') : this.$t('mailtext.introduction-offer-eRod');
      }

      let yourcontacts = this.$t('mailtext.yourcontacts');
      if (this.selectedType === 'eRod') {
        yourcontacts = this.$t('mailtext.yourcontacts-eRod');
      }

      // add the translated mailtext
      personTranslated.mailtext = {
        subject: (this.localPerson.task === 'order') ? this.$t('mailtext.subject-order') : this.$t('mailtext.subject-offer'),
        introduction,
        offertext: (this.localPerson.task === 'offer') ? this.$t('mailtext.offer-text') : '',
        yourvehicle: this.$t('mailtext.yourvehicle'),
        yourdata: this.$t('mailtext.yourdata'),
        yourcontacts,
        team: this.$t('mailtext.team'),
        export: (this.$store.state.order.delivery === 2) ? this.$t('content.delivery-export') : '',
      };

      // translate order
      const { order } = orderData;

      const orderTranslated = {};
      Object.keys(order).forEach((key) => {
        if (key !== 'previewImage' && key !== 'accessories') {
          orderTranslated[key] = {
            key: this.$t(`content.${key}`),
            value: order[key],
          };
        } else if (key === 'accessories') {
          orderTranslated.accessories = [];
          Object.keys(order[key]).forEach((accessory) => {
            orderTranslated.accessories.push({
              key: this.$t(`content.${accessory}`),
              value: order[key][accessory],
            });
          });
        }
      });

      return {
        person: personTranslated,
        order: orderTranslated,
      };
    },
    scrollToError() {
      setTimeout(() => {
        const firstErrorElement = document.getElementsByClassName('error--text')[0];
        window.scroll(
          {
            top: firstErrorElement.offsetTop,
            left: 0,
            behavior: 'smooth',
          },
        );
      }, 500);
    },
  },
};
</script>

<style lang="scss">

.termination {
  max-width: 960px;

  .optional-row {
    display: flex;

    &.hidden {
      display: none;
    }
  }

  .accept {
    label a:hover {
      text-decoration: none;
    }
  }

  .v-text-field .v-input__slot {
    border-radius: 0 !important;
    border-color: #f4f4f4 !important;
    background-color: #f4f4f4 !important;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0;
  }

  .v-input__control .v-text-field__details .v-messages__message {
    font-weight: 600;
  }

  @media (max-width: 960px) {
    .termination {
      max-width: 90%;
    }
  }

  @media (max-width: 600px) {
    .termination {
      max-width: 95%;
    }
    .accept {
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .vehicle-preview {
    margin: 0 auto;
  }
}
</style>
