<template>
  <v-container
    style="height:100%;"
    fluid
    class="mt-lg-10"
  >
    <v-row
      class="justify-center align-center pl-sm-2 pr-sm-2"
    >
      <v-col
        v-for="(type, index) in activeTypes"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <v-card
          class="card card--hover card--clickable"
        >
          <img
            :src="type.imageUrl"
            width="600"
            height="400"
            class="card__image"
            @click.once="selectType(type.id)"
          >
          <v-card-title
            class="card__title pb-1"
            @click.once="selectType(type.id)"
          >
            <p>{{ type.name[$i18n.locale] }}</p>
          </v-card-title>

          <v-card-text
            class="card__text card__text--typeminheight"
            @click.once="selectType(type.id)"
          >
            <p v-html="type.description[$i18n.locale]" /> <!-- eslint-disable-line -->
          </v-card-text>

          <v-card-actions class="type-actions">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <a
                  v-if="type.infoLink !== undefined && type.infoLink[$i18n.locale] !== ''"
                  :href="type.infoLink[$i18n.locale]"
                  class="card-footer-link"
                  target="_blank"
                  rel="noopener"
                  v-on="on"
                >
                  <v-icon
                    color="primary"
                    small
                  >
                    mdi-information-outline
                  </v-icon>&nbsp;{{ $t('content.moreinfo') }}
                </a>
              </template>
              <v-icon
                color="white"
                style="padding:0;"
              >
                mdi-arrow-top-right
              </v-icon>
            </v-tooltip>
            <v-spacer />
            <v-btn
              v-if="selectedType !== type.id"
              color="primary"
              tile
              outlined
              :large="$vuetify.breakpoint.lgAndUp"
              :aria-label="$t('gui.select')"
              @click.once="selectType(type.id)"
            >
              {{ $t('gui.select') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :large="$vuetify.breakpoint.lgAndUp"
              tile
              :aria-label="$t('gui.selected')"
              @click.once="selectType(type.id)"
            >
              <v-icon class="mr-2">
                mdi-check-bold
              </v-icon>{{ $t('gui.selected') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
  name: 'Type', // step-1
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
    }),
    activeTypes() {
      return this.$kyburz.types.filter((type) => type.active);
    },
  },
  mounted() {
    this.checkStepComplete();
    this.$track(this.$options.name);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
  methods: {
    selectType(typeId) {
      if (typeId !== this.selectedType) {
        this.$store.commit('RESET_ORDER');
      }

      this.$store.commit('SELECT_TYPE', typeId);
      this.$track(this.$options.name, 'choose', typeId, null, null);
      this.mixinSendScrollUpCommandToParentPage();

      this.checkStepComplete();
      setTimeout(() => {
        this.$router.push({ name: 'step-2' }).catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
        });
      }, 750);
    },
    checkStepComplete() {
      if (this.selectedType !== null) {
        this.$store.commit('SET_STEP_COMPLETE', 1);
      }
    },
  },
};
</script>
