import { kyburz } from '../plugins/kyburz';

const defaultSteps = JSON.parse(JSON.stringify(kyburz.steps));

const defaultOrder = {
  type: null,
  vehicle: null,
  version: null,
  color: null,
  colorRal: null,
  front: null,
  back: null,
  rim: null,
  windshield: null,
  wing: null,
  sidepanel: null,
  door: null,
  foiling: null,
  roof: null,
  accessories: {},
  delivery: 1,
  instruction: false,
  countVehicles: 1,
  previewImage: null,
};

const defaultPerson = {
  task: null, // order|offer
  gender: null,
  firstname: null,
  lastname: null,
  company: null,
  street: null,
  zip: null,
  city: null,
  country: null,
  email: null,
  phone: null,
  dateofbirth: null,
  message: null,
  agb: false,
  datenschutz: false,
  lang: 'de',
};

export default {
  SET_VIEW_MODE: (state, mode) => {
    state.viewMode = mode;
  },
  SET_CURRENT_STEP: (state, step) => {
    state.currentStep = step;
  },
  GO_NEXT_STEP: (state) => {
    state.currentStep += 1;
  },
  SELECT_TYPE: (state, typeId) => {
    state.order = JSON.parse(JSON.stringify(defaultOrder));
    state.steps = JSON.parse(JSON.stringify(defaultSteps));
    state.order.type = typeId;

    // overwrite the order with the default configuration for the selected vehicle type
    const defaultOrderForType = (kyburz.types.find((type) => type.id === typeId) || {}).defaultOrder;
    Object.keys(defaultOrderForType).forEach((property) => {
      state.order[property] = defaultOrderForType[property];
    });
  },
  SELECT_VEHICLE: (state, vehicleId) => {
    state.order.vehicle = vehicleId;
  },
  SELECT_VERSION: (state, version) => {
    state.order.version = version;
  },
  SELECT_COLOR: (state, colorId) => {
    state.order.color = colorId;
  },
  SELECT_COLOR_RAL: (state, colorRalString) => {
    state.order.colorRal = colorRalString;
  },
  SELECT_FRONT: (state, frontId) => {
    state.order.front = frontId;
  },
  SELECT_BACK: (state, backId) => {
    state.order.back = backId;
  },
  SELECT_RIM: (state, rimId) => {
    state.order.rim = rimId;
  },
  SELECT_WINDSHIELD: (state, windshieldId) => {
    state.order.windshield = windshieldId;
  },
  SELECT_WING: (state, wingId) => {
    state.order.wing = wingId;
  },
  SELECT_SIDEPANEL: (state, sidepanelId) => {
    state.order.sidepanel = sidepanelId;
  },
  SELECT_FOILING: (state, foilingId) => {
    state.order.foiling = foilingId;
  },
  SELECT_ROOF: (state, roofId) => {
    state.order.roof = roofId;
  },
  SELECT_DOOR: (state, doorId) => {
    state.order.door = doorId;
  },
  SELECT_DELIVERY: (state, value) => {
    state.order.delivery = value;
  },
  SELECT_INSTRUCTION: (state, value) => {
    state.order.instruction = value;
  },
  SELECT_FRONTWINDOW: (state, value) => {
    state.order.accessories.frontWindow = value;
  },
  SELECT_KNEEBLANKET: (state, value) => {
    state.order.accessories.kneeblanket = value;
  },
  SELECT_GLOVE: (state, value) => {
    state.order.accessories.glove = value;
  },
  SELECT_BATTERY: (state, value) => {
    state.order.accessories.battery = value;
  },
  SELECT_BIGBATTERY: (state, value) => {
    state.order.accessories.bigBattery = value;
  },
  SELECT_MARKING: (state, value) => {
    state.order.accessories.marking = value;
  },
  SELECT_COVER: (state, value) => {
    state.order.accessories.cover = value;
  },
  SELECT_CANEHOLDER: (state, value) => {
    state.order.accessories.caneholder = value;
  },
  SELECT_SEAT: (state, value) => {
    state.order.accessories.seat = value;
  },
  SELECT_CHARGER: (state, value) => {
    state.order.accessories.charger = value;
  },
  SELECT_BUCKETSEAT: (state, value) => {
    state.order.accessories.bucketSeat = value;
  },
  SELECT_TIRE: (state, value) => {
    state.order.accessories.tire = value;
  },
  SELECT_SCHUKOADAPTER: (state, value) => {
    state.order.accessories.schukoAdapter = value;
  },
  SELECT_DIFFERENTIAL: (state, value) => {
    state.order.accessories.differential = value;
  },
  SELECT_ADAPTERCABLE: (state, value) => {
    state.order.accessories.adapterCable = value;
  },
  SELECT_WEATHERPROTECTION: (state, value) => {
    state.order.accessories.weatherProtection = value;
  },
  SELECT_SUITCASE: (state, value) => {
    state.order.accessories.suitcase = value;
  },
  SELECT_FLEETMANAGEMENT: (state, value) => {
    state.order.accessories.fleetManagement = value;
  },
  SELECT_HANDLING: (state, value) => {
    state.order.accessories.handling = value;
  },
  SELECT_DOOROPENHOLDER: (state, value) => {
    state.order.accessories.doorOpenHolder = value;
  },
  SELECT_NAVIPLUGSOCKET: (state, value) => {
    state.order.accessories.naviPlugSocket = value;
  },
  SELECT_USBPLUGSOCKET: (state, value) => {
    state.order.accessories.usbPlugSocket = value;
  },
  SELECT_TOWCOUPLING: (state, value) => {
    state.order.accessories.towCoupling = value;
  },
  SELECT_SUNVISOR: (state, value) => {
    state.order.accessories.sunVisor = value;
  },
  SELECT_HEADREST: (state, value) => {
    state.order.accessories.headrest = value;
  },
  SELECT_ROLLATOR: (state, value) => {
    state.order.accessories.rollator = value;
  },
  SELECT_NAVIDEVICE: (state, value) => {
    state.order.accessories.naviDevice = value;
  },
  SELECT_TRAILER: (state, value) => {
    state.order.accessories.trailer = value;
  },
  SELECT_RAINPROTECTION: (state, value) => {
    state.order.accessories.rainProtection = value;
  },
  SELECT_SPRINGSEAT: (state, value) => {
    state.order.accessories.springSeat = value;
  },
  SELECT_REKUMODULE: (state, value) => {
    state.order.accessories.rekuModule = value;
  },
  SELECT_KEYLESSGO: (state, value) => {
    state.order.accessories.keylessGo = value;
  },
  SELECT_WINTERWHEEL: (state, value) => {
    state.order.accessories.winterWheel = value;
  },
  SELECT_RADIO: (state, value) => {
    state.order.accessories.radio = value;
  },
  SELECT_HEATEDGRIP: (state, value) => {
    state.order.accessories.heatedGrip = value;
  },
  SELECT_HEATEDFLOOR: (state, value) => {
    state.order.accessories.heatedFloor = value;
  },
  SELECT_DISTRESSBUTTON: (state, value) => {
    state.order.accessories.distressButton = value;
  },
  SELECT_ACCESSORYROOF: (state, value) => {
    state.order.accessories.accessoryRoof = value;
  },
  SELECT_BLACKRIMSET: (state, value) => {
    state.order.accessories.blackRimSet = value;
  },
  SELECT_SINGLEINSPECTION: (state, value) => {
    state.order.accessories.singleInspection = value;
  },
  SELECT_COUNT_VEHICLES: (state, count) => {
    state.order.countVehicles = count;
  },
  SET_PREVIEW_URL: (state, imageDataUrl) => {
    state.order.previewImage = imageDataUrl;
  },
  SET_STEP_COMPLETE: (state, stepId) => {
    const step = state.steps.find((element) => element.id === stepId);
    step.complete = true;
  },
  UNSET_STEP_COMPLETE: (state, stepId) => {
    const step = state.steps.find((element) => element.id === stepId);
    step.complete = false;
  },
  SET_PERSON: (state, person) => {
    state.person = person;
  },
  SET_PERSON_ORDER_TASK: (state, task) => {
    state.person.task = task;
  },
  SET_PERSON_LANG: (state, lang) => {
    state.person.lang = lang;
  },
  RESET_ORDER: (state) => {
    state.order = JSON.parse(JSON.stringify(defaultOrder));
  },
  RESET_CONFIGURATOR: (state) => {
    state.lastStep = 7;
    state.order = JSON.parse(JSON.stringify(defaultOrder));
    state.person = JSON.parse(JSON.stringify(defaultPerson));
    state.steps = JSON.parse(JSON.stringify(defaultSteps));
  },
  RESET_STEPS_COMPLETE: (state) => {
    state.steps = JSON.parse(JSON.stringify(defaultSteps));
  },
  SET_PREVIOUS_STEPS_INCOMPLETE: (state, beforeStep) => {
    for (let i = 0; i < beforeStep; i += 1) {
      state.steps[i].complete = false;
    }
  },
};
