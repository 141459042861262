import steps from '../data/steps.json';

import types from '../data/types.json';
import vehicles from '../data/vehicles.json';

import colors from '../data/colors.json';
import fronts from '../data/fronts.json';
import backs from '../data/backs.json';
import rims from '../data/rims.json';
import windshields from '../data/windshields.json';
import wings from '../data/wings.json';
import sidepanels from '../data/sidepanels.json';
import doors from '../data/doors.json';
import foilings from '../data/foilings.json';
import roofs from '../data/roofs.json';

import deliveries from '../data/deliveries.json';
import instructions from '../data/instructions.json';

// accessories
import frontWindows from '../data/accessories/frontWindows.json';
import kneeblankets from '../data/accessories/kneeblankets.json';
import gloves from '../data/accessories/gloves.json';
import batteries from '../data/accessories/batteries.json';
import bigBatteries from '../data/accessories/bigBatteries.json';
import markings from '../data/accessories/markings.json';
import covers from '../data/accessories/covers.json';
import caneholders from '../data/accessories/caneholders.json';
import seats from '../data/accessories/seats.json';
import chargers from '../data/accessories/chargers.json';
import bucketSeats from '../data/accessories/bucketSeats.json';
import tires from '../data/accessories/tires.json';
import schukoAdapters from '../data/accessories/schukoAdapters.json';
import adapterCables from '../data/accessories/adapterCables.json';
import weatherProtections from '../data/accessories/weatherProtections.json';
import suitcases from '../data/accessories/suitcases.json';
import fleetManagements from '../data/accessories/fleetManagements.json';
import differentials from '../data/accessories/differentials.json';
import handlings from '../data/accessories/handlings.json';
import doorOpenHolders from '../data/accessories/doorOpenHolders.json';
import naviPlugSockets from '../data/accessories/naviPlugSockets.json';
import usbPlugSockets from '../data/accessories/usbPlugSockets.json';
import towCouplings from '../data/accessories/towCouplings.json';
import sunVisors from '../data/accessories/sunVisors.json';
import headrests from '../data/accessories/headrests.json';
import rollators from '../data/accessories/rollators.json';
import naviDevices from '../data/accessories/naviDevices.json';
import trailers from '../data/accessories/trailers.json';
import rainProtections from '../data/accessories/rainProtections.json';
import springSeats from '../data/accessories/springSeats.json';
import rekuModules from '../data/accessories/rekuModules.json';
import keylessGos from '../data/accessories/keylessGos.json';
import winterWheels from '../data/accessories/winterWheels.json';
import radios from '../data/accessories/radios.json';
import heatedGrips from '../data/accessories/heatedGrips.json';
import heatedFloors from '../data/accessories/heatedFloors.json';
import distressButtons from '../data/accessories/distressButtons.json';
import accessoryRoofs from '../data/accessories/accessoryRoofs.json';
import blackRimSets from '../data/accessories/blackRimSets.json';
import singleInspections from '../data/accessories/singleInspections.json';

import ralColors from '../data/ralColors.json';

export const kyburz = {
  steps,
  types,
  vehicles,
  colors,
  fronts,
  backs,
  rims,
  windshields,
  wings,
  sidepanels,
  doors,
  foilings,
  roofs,
  accessories: {
    frontWindows,
    kneeblankets,
    gloves,
    batteries,
    bigBatteries,
    markings,
    covers,
    caneholders,
    seats,
    chargers,
    bucketSeats,
    tires,
    schukoAdapters,
    adapterCables,
    weatherProtections,
    suitcases,
    fleetManagements,
    differentials,
    handlings,
    doorOpenHolders,
    naviPlugSockets,
    usbPlugSockets,
    towCouplings,
    sunVisors,
    headrests,
    rollators,
    naviDevices,
    trailers,
    rainProtections,
    springSeats,
    rekuModules,
    keylessGos,
    winterWheels,
    radios,
    heatedGrips,
    heatedFloors,
    distressButtons,
    accessoryRoofs,
    blackRimSets,
    singleInspections,
  },
  deliveries,
  instructions,
  ralColors,
};

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$kyburz = kyburz;
  },
};
