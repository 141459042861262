<template>
  <div
    id="vehicle-preview"
    class="vehicle-preview"
  >
    <img
      v-if="selectedVehicle === 'DXC' && selectedRoof === 2 && selectedBack !== 10 && selectedBack !== 11"
      id="image-roof-fixation"
      ref="roof-fixation"
      class="vehicle-preview__image roof-fixation"
      src="img/views/DXC/DXC-r-roof-fixation.png"
      alt=""
    >

    <img
      v-if="selectedVehicle === 'DXC'"
      id="image-roof"
      ref="roof"
      class="vehicle-preview__image roof"
      :src="roofVehicleImageUrl"
      alt=""
    >

    <img
      v-if="frontVehicleImageUrl"
      class="vehicle-preview__image front"
      :src="frontVehicleImageUrl"
      alt=""
    >
    <img
      v-if="backVehicleImageUrl"
      class="vehicle-preview__image back"
      :src="backVehicleImageUrl"
      alt=""
    >
    <img
      v-if="rimVehicleImageUrl"
      class="vehicle-preview__image rim"
      :src="rimVehicleImageUrl"
      alt=""
    >
    <img
      v-if="windshieldVehicleImageUrl"
      class="vehicle-preview__image windshield"
      :src="windshieldVehicleImageUrl"
      alt=""
    >
    <img
      v-if="wingVehicleImageUrl"
      class="vehicle-preview__image wing"
      :src="wingVehicleImageUrl"
      alt=""
    >
    <img
      v-if="sidepanelVehicleImageUrl"
      class="vehicle-preview__image sidepanel"
      :src="sidepanelVehicleImageUrl"
      alt=""
    >
    <img
      v-if="doorVehicleImageUrl"
      class="vehicle-preview__image door"
      :src="doorVehicleImageUrl"
      alt=""
    >

    <img
      v-if="(selectedType !== 'PLUS' && selectedType !== 'PLUS_II')"
      class="vehicle-preview__image all"
      :src="colorVehicleImageUrl"
      alt=""
    >
    <div
      v-if="(selectedVehicle === 'DXC' && selectedColor === 99)"
      class="color-ral"
      :style="`background-color:${hexColorRal};`"
    />

    <div
      v-if="(selectedVehicle === 'DXC' && selectedColor !== 99)"
      class="color-ral"
      :style="`background-color:#e2e2e1;`"
    />
    <div
      v-if="selectedType === 'eRod' && selectedFoiling !== 1"
      ref="foiling"
      class="vehicle-preview__image foiling"
      :style="`background-color:${selectedFoilingColorCode};`"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import vehicles from '../data/vehicles.json';

export default {
  name: 'VehiclePreview',
  computed: {
    ...mapState({
      order: (state) => state.order,
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
      selectedColor: (state) => state.order.color,
      selectedFront: (state) => state.order.front,
      selectedBack: (state) => state.order.back,
      selectedRim: (state) => state.order.rim,
      selectedWindshield: (state) => state.order.windshield,
      selectedWing: (state) => state.order.wing,
      selectedSidepanel: (state) => state.order.sidepanel,
      selectedDoor: (state) => state.order.door,
      colorRal: (state) => state.order.colorRal,
      selectedFoiling: (state) => state.order.foiling,
      selectedRoof: (state) => state.order.roof,
    }),
    vehicle() {
      return vehicles.find((veh) => veh.id === this.order.vehicle);
    },
    colorVehicleImageUrl() {
      if (this.selectedType === 'eRod') {
        if (this.selectedFoiling === 1) {
          return this.vehicle.colorImageUrl[this.selectedColor].nofoiling;
        }
        return this.vehicle.colorImageUrl[this.selectedColor].foiling;
      }
      return this.vehicle.colorImageUrl[this.selectedColor];
    },
    frontVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'frontImageUrl')) {
        return this.vehicle.frontImageUrl[this.selectedFront];
      }
      return null;
    },
    backVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'backImageUrl')) {
        return this.vehicle.backImageUrl[this.selectedBack];
      }
      return null;
    },
    rimVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'rimImageUrl')) {
        return this.vehicle.rimImageUrl[this.selectedRim];
      }
      return null;
    },
    windshieldVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'windshieldImageUrl')) {
        return this.vehicle.windshieldImageUrl[this.selectedWindshield];
      }
      return null;
    },
    wingVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'wingImageUrl')) {
        return this.vehicle.wingImageUrl[this.selectedWing];
      }
      return null;
    },
    sidepanelVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'sidepanelImageUrl')) {
        return this.vehicle.sidepanelImageUrl[this.selectedSidepanel];
      }
      return null;
    },
    doorVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'doorImageUrl')) {
        return this.vehicle.doorImageUrl[this.selectedDoor][this.selectedColor];
      }
      return null;
    },
    roofVehicleImageUrl() {
      if (Object.prototype.hasOwnProperty.call(this.vehicle, 'roofImageUrl')) {
        return this.vehicle.roofImageUrl[this.selectedRoof];
      }
      return null;
    },
    hexColorRal() {
      return this.$kyburz.ralColors[this.colorRal];
    },
    ralCircleTextColor() {
      // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
      // if (red*0.299 + green*0.587 + blue*0.114) > 186 use #000000 else use #ffffff
      let result = '';
      if (this.hexColorRal !== null) {
        const hexToRgb = (hex) => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
          .substring(1).match(/.{2}/g)
          .map((x) => parseInt(x, 16));

        const colorRgb = hexToRgb(this.hexColorRal);
        if ((colorRgb[0] * 0.299 + colorRgb[1] * 0.587 + colorRgb[2] * 0.114) > 186) {
          result = '#000000';
        } else {
          result = '#ffffff';
        }
      }

      return result;
    },
    selectedFoilingColorCode() {
      const foiling = this.$kyburz.foilings.find((foil) => foil.id === this.selectedFoiling);
      if (foiling) {
        return foiling.colorCode;
      }
      return '#FFFFFF';
    },
  },
  mounted() {
    if (this.$refs && Object.prototype.hasOwnProperty.call(this.$refs, 'foiling')) {
      setTimeout(() => {
        this.$refs.foiling.classList.add('visible');
      }, 250);
    }
  },
};

</script>

<style lang="scss">

$image-width: 530px;
$image-height: 353px;

$padding-top: 40px;

$image-width-mobile: 300px;
$image-height-mobile: 200px;

.vehicle-preview {
  display: block;
  position: relative;
  width: $image-width;
  height: $image-height + $padding-top;

  &__image {
    position: absolute;
    width: $image-width;
    height: $image-height;
    top: $padding-top;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    &.front {
      z-index: 6;
    }

    &.back {
      z-index: 5;
    }

    &.rim {
      z-index: 5;
    }

    &.windshield {
      z-index: 4;
    }

    &.wing {
      z-index: 4;
    }

    &.sidepanel {
      z-index: 4;
    }

    &.door {
      z-index: 4;
    }

    &.all {
      z-index: 3;
    }

    &.roof {
      z-index: 7;
    }

    &.roof-fixation {
      z-index: 8;
    }

    &.foiling {
      z-index: 2;
      opacity: 0;

      &.visible {
        opacity: 1;
        transition: opacity .3s ease-in;
      }
    }
  }
}

.color-ral {
  z-index: 1;
  width: $image-width;
  height: $image-height - 2px;
  position: absolute;
  top: $padding-top;
  right: 0;
  bottom: 2px;
  left: 0;
}

@media (max-width: 600px) {

  .vehicle-preview {
    width: $image-width-mobile;
    height: $image-height-mobile + $padding-top;
    margin: 0 auto;

    &__image {
      width: $image-width-mobile;
      height: $image-height-mobile;
    }

    .color-ral {
      width: $image-width-mobile;
      height: $image-height-mobile;

      &::before {
        width: $image-width-mobile;
        height: $image-height-mobile;
      }
    }
  }
}
</style>
