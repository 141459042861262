<template>
  <v-card
    class="card"
    :disabled="!vehicle.available"
    :class="{ 'card--2ndlife': selectedType === 'DXS-2ndLife' }"
  >
    <div
      v-if="selectedType === 'eRod'"
      :style="`height:${eRodImageHeight}px;overflow:hidden;background-color: white;`"
    >
      <img
        v-if="selectedVersion !== null"
        :src="(selectedVersion === 'eRod-fertig') ? 'img/modelle/eRod.jpg' : 'img/modelle/eRod-Bauseminar.jpg' "
        class="card__image"
        width="366"
        height="270"
        @click="selectVehicle(vehicle.id)"
      >

      <div
        v-else
      >
        <v-carousel
          cycle
          continuous
          interval="4000"
          :height="eRodImageHeight"
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(eRodImage, i) in eRodImages"
            :key="i"
            transition="cycle"
            reverse-transition="cycle"
            eager
            :src="`img/modelle/${eRodImage}`"
          />
        </v-carousel>
      </div>
    </div>

    <div v-else>
      <img
        class="card__image"
        :src="vehicle.imageUrl"
        width="366"
        height="270"
        @click="selectVehicle(vehicle.id)"
      >
    </div>

    <v-card-title
      class="card__title pb-1 pb-sm-3"
    >
      {{ vehicle.name[$i18n.locale] }}
    </v-card-title>

    <v-card-text
      class="card__text pb-1"
    >
      <p
        class="mb-0 mb-sm-3"
      >
        {{ vehicle.info[$i18n.locale] }}
      </p>

      <p
        v-if="vehiclePrice"
        class="font-weight-bold mb-0"
      >
        {{ vehiclePrice | toCurrency }}
      </p>

      <p
        v-else
        class="font-weight-bold mb-0"
      >
        <span v-if="selectedType !== 'eRod' && selectedType !== 'DXS-2ndLife'">{{ $t('content.select-velocity') }}</span>
        <span v-else-if="selectedType === 'DXS-2ndLife'">{{ $t('content.select-battery') }}</span>
        <span v-else>{{ $t('content.select-version') }}</span>
      </p>

      <p
        v-if="vehicle.available && vehicle.deliveryTime"
        class="grey--text lighten-1 mb-0"
      >
        {{ $t('content.delivery-in', { weeks: vehicle.deliveryTime }) }}
      </p>
      <p
        v-if="!vehicle.available"
        class="error--text mb-0"
      >
        {{ $t('content.not-available') }}
      </p>
    </v-card-text>

    <v-card-text
      v-if="versions && versions.length > 1"
      class="card__text pt-0 pb-0 card--version"
    >
      <v-radio-group
        v-model="selectedVersion"
        :label="radioLabel"
        :error="pleaseSelectVelocity"
      >
        <v-radio
          v-for="version in versions"
          :key="version.value"
          :label="version.text + (($debug) ? '       ' + version.artNr : '')"
          :value="version.value"
          color="primary"
        >
          <template v-slot:label>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="version.text + (($debug) ? ' <code>' + version.artNr + '</code>' : '')" />
          </template>
        </v-radio>
      </v-radio-group>
      <div
        class="please-select"
      >
        <p
          v-if="pleaseSelectVelocity"
        >
          {{ pleaseSelectText }}
        </p>
      </div>
    </v-card-text>

    <v-card-text
      v-else
      class="card__text card--version"
    >
      <p v-if="selectedType !== 'DXS-2ndLife'">
        {{ $t('content.velocity') }}: {{ versions[0].text }}
      </p>
      <p v-else>
        {{ $t('content.battery-select') }}: {{ versions[0].text }}
      </p>
      <v-radio-group
        :value="versions[0].value"
        class="d-none"
      >
        <v-radio
          v-for="version in versions"
          :key="version.value"
          :label="version.text"
          :value="version.value"
          color="primary"
        />
      </v-radio-group>
      <div
        class="please-select"
      >
        <p
          v-if="pleaseSelectVelocity"
        >
          {{ pleaseSelectText }}
        </p>
      </div>
    </v-card-text>

    <v-card-actions
      :class="{ 'pt-6': versions.length === 1 }"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <a
            v-if="vehicle.techDataLink !== undefined && vehicle.techDataLink[$i18n.locale] !== ''"
            :href="vehicle.techDataLink[$i18n.locale]"
            class="card-footer-link"
            target="_blank"
            rel="noopener"
            v-on="on"
          >
            <v-icon
              color="primary"
              small
            >
              mdi-file-pdf-outline
            </v-icon>&nbsp;{{ $t('content.techdata') }}
          </a>
        </template>
        <v-icon
          color="white"
          style="padding:0;"
        >
          mdi-arrow-top-right
        </v-icon>
      </v-tooltip>
      <v-spacer />
      <v-btn
        v-if="selectedVehicle != vehicle.id"
        color="primary"
        tile
        outlined
        :large="$vuetify.breakpoint.mdAndUp"
        :aria-label="$t('gui.select')"
        @click="selectVehicle(vehicle.id)"
      >
        {{ $t('gui.select') }}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :large="$vuetify.breakpoint.mdAndUp"
        tile
        :aria-label="$t('gui.selected')"
        @click="selectVehicle(vehicle.id)"
      >
        <v-icon class="mr-2">
          mdi-check-bold
        </v-icon>{{ $t('gui.selected') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
  name: 'VehicleCard', // component used in step-2
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    pleaseSelectVelocity: false,
    eRodImages: [
      'eRod.jpg',
      'eRod-Bauseminar.jpg',
    ],
  }),
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    radioLabel() {
      if (this.selectedType === 'eRod') {
        return this.$t('content.version');
      }
      if (this.selectedType === 'DXS-2ndLife') {
        return this.$t('content.battery-select');
      }
      return this.$t('content.velocity');
    },
    selectButtonDisabled() {
      if (!this.vehicle.available) {
        return true;
      }

      if (this.vehiclePrice === null) {
        return true;
      }

      return false;
    },
    versions() {
      if (this.vehicle.versions && this.vehicle.versions.length > 0) {
        const versions = [];
        this.vehicle.versions.forEach((version) => {
          versions.push({
            text: version.name[this.$i18n.locale],
            value: version.id,
            artNr: version.artNr,
          });
        });
        return versions;
      }
      return null;
    },
    selectedVersion: {
      get() {
        if (this.versions.length === 1) {
          return this.versions[0].value;
        }
        return this.$store.state.order.version;
      },
      set(value) {
        this.$store.commit('SELECT_VERSION', value);
      },
    },
    vehiclePrice() {
      if (this.vehicle.versions) {
        const versionPrice = this.vehicle.versions.find((element) => {
          if (element.id === this.selectedVersion) {
            return true;
          }
          return false;
        });

        if (versionPrice) {
          return versionPrice.price;
        }
        return null;
      }
      return this.vehicle.price;
    },
    eRodImageHeight() {
      if (this.$vuetify.breakpoint.xlOnly === true) {
        return 400;
      }
      if (this.$vuetify.breakpoint.xs === true) {
        return 220;
      }
      return 270;
    },
    pleaseSelectText() {
      if (this.selectedType === 'eRod') {
        return this.$t('content.select-version-for-vehicle');
      }
      if (this.selectedType === 'DXS-2ndLife') {
        return this.$t('content.select-battery-for-vehicle');
      }
      return this.$t('content.select-velocity-for-vehicle');
    },
  },
  watch: {
    selectedVersion(newValue) {
      if (newValue !== null) {
        this.pleaseSelectVelocity = false;
        if (!newValue.startsWith(this.selectedVehicle)) {
          this.$store.commit('UNSET_STEP_COMPLETE', 2);
        }
      }
    },
  },
  mounted() {
    if (this.selectedType === 'DXC') {
      this.$store.commit('SELECT_VEHICLE', 'DXC');
      this.$store.commit('SELECT_VERSION', 'DXC-45');
      this.$store.commit('SET_STEP_COMPLETE', 2);
    }
  },
  methods: {
    selectVehicle(vehicleId) {
      if (
        this.selectedVersion !== null
        && this.selectedVersion.startsWith(this.vehicle.id)
      ) {
        this.pleaseSelectVelocity = false;
        this.$store.commit('SELECT_VEHICLE', vehicleId);
        this.$track(this.$parent.$options.name, 'choose', this.selectedVersion, this.selectedType, null);
        if (this.versions.length === 1) {
          this.$store.commit('SELECT_VERSION', this.selectedVersion);
        }
        this.$store.commit('SET_STEP_COMPLETE', 2);
        setTimeout(() => {
          this.mixinSendScrollUpCommandToParentPage();
          this.$router.push({ name: 'step-3' }).catch((error) => {
            if (this.$isDev) {
              console.error(error); // eslint-disable-line
            } else {
              Sentry.captureException(error);
            }
          });
        }, 750);
      } else {
        this.pleaseSelectVelocity = true;
        // this.$emit('pleaseSelectVelocity');
      }
    },
  },
};
</script>
