<template>
  <v-app
    :class="{ 'embed': (viewMode === 'embed') }"
  >
    <Header
      v-if="viewMode === 'standalone'"
    />
    <Stepper />
    <Footer />

    <h1
      class="content-title text-center d-md-none mt-4 mb-4 mb-sm-16"
    >
      {{ stepName }}
    </h1>
    <v-main class="pt-0">
      <transition
        name="fade"
        appear
        mode="out-in"
      >
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import compareVersions from 'compare-versions';
import * as Sentry from '@sentry/vue';

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Stepper from './components/Stepper.vue';

export default {
  name: 'App',
  components: {
    Header,
    Stepper,
    Footer,
  },
  computed: {
    ...mapState({
      viewMode: (state) => state.viewMode,
      currentStep: (state) => state.currentStep,
      lastStep: (state) => state.lastStep,
      steps: (state) => state.steps,
      sheet: (state) => state.sheet,
      selectedType: (state) => state.order.type,
    }),
    stepName() {
      const step = this.steps.find((st) => st.id === this.currentStep);
      if (step) {
        return step.name[this.$i18n.locale];
      }
      return null;
    },
  },
  created() {
    this.checkViewMode();

    window.onresize = () => {
      document.body.height = window.innerHeight;
    };
    window.onresize(); // called to initially set the height.

    this.checkLanguage();
    this.checkStorageAndPreselection();

    if (
      this.currentStep === this.lastStep
      && this.steps[6].complete === true
    ) {
      this.$store.commit('RESET_ORDER');
      this.$store.commit('RESET_STEPS_COMPLETE');
      this.$store.commit('SET_CURRENT_STEP', 1);
    }
  },
  updated() {
    document.title = this.$t('gui.html-title');
    document.querySelector('head meta[name="description"]').setAttribute('content', this.$t('gui.meta-description'));
  },
  methods: {
    checkViewMode() {
      const viewMode = this.getUrlParameter('view');
      if (viewMode && viewMode === 'embed') {
        this.$store.commit('SET_VIEW_MODE', 'embed');
      } else {
        this.$store.commit('SET_VIEW_MODE', 'standalone');
      }
    },
    checkLanguage() {
      /**
       * language preselect
       *
       * can come from
       * - localStorage
       * - url-parameter "lang"
       *
       */
      if (this.viewMode === 'embed') {
        const urlParameterLang = this.getUrlParameter('lang');
        if (urlParameterLang && this.isLanguageValid(urlParameterLang)) {
          localStorage.setItem('kyburz-configurator-lang', urlParameterLang);
          // if a lang-parameter is set and valid, set the vue app in this language
          this.$store.commit('SET_PERSON_LANG', urlParameterLang);
          this.$i18n.locale = urlParameterLang;
        }
      } else {
        const localStorageLang = localStorage.getItem('kyburz-configurator-lang');
        if (localStorageLang) {
          // if language is set in localStorage, set the vue app in this language
          this.$store.commit('SET_PERSON_LANG', localStorageLang);
          this.$i18n.locale = localStorageLang;
        } else {
          // if no language is set in localStorage, check if language is set by url-parameter
          const urlParameterLang = this.getUrlParameter('lang');
          if (urlParameterLang && this.isLanguageValid(urlParameterLang)) {
            // if a lang-parameter is set and valid, set the vue app in this language
            this.$store.commit('SET_PERSON_LANG', urlParameterLang);
            this.$i18n.locale = urlParameterLang;
          }
        }
      }
    },
    checkStorageAndPreselection() {
      /**
       * if vehicle-type preselection is valid, set this type as selected
       * and go to step 2
       */
      const preselectedType = this.getUrlParameter('type');

      /* eslint-disable */
      const versionNumber = require('../package.json').version;
      const localStorageVersionNumber = window.localStorage.getItem('kyburz-configurator-version');

      if (localStorageVersionNumber !== null && compareVersions(versionNumber, localStorageVersionNumber) !== 0) {
        this.$store.commit('RESET_CONFIGURATOR');
      }
      window.localStorage.setItem('kyburz-configurator-version', versionNumber);

      if (preselectedType && preselectedType !== '') {
        this.$store.commit('SELECT_TYPE', preselectedType);
        this.$store.commit('SET_STEP_COMPLETE', 1);
        if (preselectedType === 'DXC') {
          this.$store.commit('SELECT_VEHICLE', 'DXC');
        }
        this.$track('Type', 'preselect', preselectedType);
        this.$router.push({ name: 'step-2' }).catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
        });
      }
      /* eslint-enable */
    },
    goNextStep() {
      this.$router.push({ name: `step-${this.currentStep + 1}` }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    goPreviousStep() {
      this.$router.push({ name: `step-${this.currentStep - 1}` }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    isTypeValid(type) {
      /**
       * returns true if vehicle-type is found in vehicle definition
       * @param string type
       */
      const filterResult = this.$kyburz.types.filter((element) => element.id === type);
      if (filterResult.length > 0) {
        return true;
      }
      return false;
    },
    isLanguageValid(lang) {
      /**
       * checks if the lang provided by url-parameter is valid
       * https://configurator.kyburz-switzerland.ch/?lang=en
       *
       * @param string lang
       */
      const validLanguages = ['de', 'en', 'fr'];
      return validLanguages.includes(lang);
    },
    getUrlParameter(parameter) {
      /**
       * returns get parameter
       *
       * used for:
       * - lang
       * - type
       *
       * @param string (url) parameter
       */
      if (window.location.search.substr(1) === '') {
        return null;
      }

      /* eslint-disable */
      const name = parameter.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      /* eslint-enable */
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}

.theme--light.v-application,
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff !important;
}

::selection {
  background-color: #1b49a6 !important;
  color: #fff !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.cycle-enter-active, .cycle-leave-active {
  opacity: 1;
  transition: opacity .25s;
}

.cycle-enter, .cycle-leave-to {
  opacity: 0;
  transition: opacity .25s;
}

.v-btn__content {
  text-transform: none !important;
}

.step-arrow {
  position: absolute;

  &--right {
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &--left {
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.content-title {
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.v-application--wrap {
  min-height: unset !important;
}

 /**
 * cards
 * - type cards
 * - vehicle cards
 * - configurator overlay cards
 * - accessory cards
 */
.card {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.5' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  border: none;
  box-shadow: none !important;
  border-radius: 0 !important;
  overflow: hidden;

  &--clickable {
    cursor: pointer;
  }

  &__title {
    font-size: 24px !important;
    line-height: 1.25 !important;
    font-weight: 700 !important;
    letter-spacing: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
    align-content: flex-start;
    color: #1a1a1a !important;

    &--minheight {
      min-height: 80px;
    }

    &--accessory {
      font-size: 17px !important;
    }
  }

  &__image-clickable {
    cursor: pointer;
  }

  &__foiling-div {
    padding-bottom: 66.6667%;
  }

  &__text {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #1a1a1a !important;

    &--minheight {
      min-height: 135px;
    }

    &--accessory {
      font-size: 15px !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--accessminheight-eRod {
      min-height: 232px;
    }

    &--accessminheight-DXS,
    &--accessminheight-DXS-2ndLife,
    &--accessminheight-DX2,
    &--accessminheight-PLUS,
    &--accessminheight-DXC {
      min-height: 160px;
    }

    &--typeminheight {
      min-height: 128px !important;
    }

    p {
      min-height: 24px;
    }

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    &--table {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &.card--wide {
    min-width: 300px;
  }

  &.card--hover:hover {

    .v-image__image {
      transform-origin: center center;
      transform: scale(1.1);
      transition: transform .3s ease-in;
    }

    .v-btn {
      background-color: #1b49a6 !important;
      color: white !important;
    }

  }

  .v-label {
    color: #1a1a1a;
  }

  .v-input--radio-group--column .v-input--radio-group__input > .v-label {
    margin-bottom: 8px;
    color: #1a1a1a;
  }

  .v-input--radio-group .v-input--radio-group__input .v-radio--is-disabled .v-label.error--text {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .v-input--selection-controls .v-radio>.v-label {
    flex-grow: 0;
    font-size: 14px;
  }

  .v-input--radio-group legend {
    align-self: flex-start;
  }

  .card-footer-link {
    display: flex;
    align-content: center;
    padding-left: 10px;
    text-decoration: none;
    font-size: 14px;

    .v-icon {
      margin-right: 3px;
    }
  }

  .type-actions {
    flex-direction: row;
  }

  .v-btn {
    transition: all .6s cubic-bezier(.15,1.05,.9,1);

    &:hover {
      border-color: #1b49a6;
      background-color: #1b49a6;
      color: #fff !important;
      transition: all .6s cubic-bezier(.15,1.05,.9,1);
    }
  }

  &--2ndlife {
    .card--version {
      min-height: 135px;
    }
  }

  .please-select {
    color: #e60000;
    min-height: 25px;

    p {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1920px) {  // LG
  .card {

    &__title {
      font-size: 18px !important;

      &--minheight {
        min-height: 78px;
      }
    }

    &__text {
      font-size: 14px !important;

      &--typeminheight {
        min-height: 116px !important;
      }

      .v-label {
        font-size: 14px !important;
      }
    }
  }

  .embed {
    .v-stepper {
      margin-top: 20px !important;
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .card {
    &__title {
      font-size: 16px !important;

      &--minheight {
        min-height: 0 !important;
      }
    }

    &__text {

      &--accessminheight-eRod {
        min-height: 225px;
      }
    }

    .card-footer-link {
      font-size: 12px;

      .v-icon {
        position: relative;
        top: -1px;
      }
    }
  }
}

@media (max-width: 960px) {
  .embed {
    .v-stepper {
      margin-top: 0 !important;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .card {
    &__title {
      &--minheight {
        min-height: 0 !important;
      }

      &--ipadminheight {
        min-height: 72px !important;
      }
    }

    &__text {

      &--accessminheight-eRod {
        min-height: 208px;
      }
    }

    .card-footer-link {
      font-size: 12px;

      .v-icon {
        position: relative;
        top: -1px;
      }
    }
  }
}

@media (max-width: 600px) {
  .card {
    margin: 0 auto;

    &__title {
      font-size: 20px;
      min-height: 0 !important;
    }

    &__text {
      &--minheight,
      &--typeminheight,
      &--accessminheight-eRod {
        min-height: 0 !important;
      }
    }
  }

  .v-label {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .card__text {

    &--table {
      margin-top: 20px;

      td {
        font-size: 11px;
        vertical-align: top;
        padding: 3px;
      }

      tr:not(:last-of-type) {
        td {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
}

.v-application {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
}

</style>
