import trackingModule from '../lib/trackingModule';

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$track = (screen, action = null, value = null, type = null, vehicle = null) => {
      trackingModule.track(screen, action, value, type, vehicle);
    };
  },
};
