import Vue from 'vue';

// 1234.00 -> CHF&nbsp;1’234
function currencyFormat(num) {
  return `CHF&nbsp;${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1’')}`;
}

// 1234.00 -> CHF 1’234
function currencyFormatNoHtml(num) {
  return `CHF ${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1’')}`;
}

// 1234.00 -> CHF 1’234
Vue.filter('toCurrency', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  return currencyFormatNoHtml(value);
});

// 1234.00 -> (CHF 1’234)
// when 0 return empty string
Vue.filter('toCurrencyRemark', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  if (value === 0) {
    return '';
  }
  return `(${currencyFormat(value)})`;
});

// 1234.00 -> CHF 1’234
// when 0 return '-'
Vue.filter('toCurrencyClear', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  if (value === 0) {
    return '-';
  }
  return `${currencyFormatNoHtml(value)}`;
});

Vue.filter('toUpperCase', (value) => {
  if (!value) return '';
  const result = value.toString();
  return result.toUpperCase();
});
