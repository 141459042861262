<template>
  <v-stepper>
    <v-stepper-header class="stepper-header">
      <StepperStep
        v-for="(step, index) in steps"
        :key="index"
        :step="step"
        :show-divider="index < (steps.length-1)"
      />
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { mapState } from 'vuex';

import StepperStep from './StepperStep.vue';

export default {
  name: 'Stepper',
  components: {
    StepperStep,
  },
  computed: {
    ...mapState({
      currentStep: (state) => state.currentStep,
      steps: (state) => state.steps,
      viewMode: (state) => state.viewMode,
    }),
  },
};
</script>

<style lang="scss">
  .v-stepper {
    margin-top: 64px;
    box-shadow: none !important;
  }

  .stepper-header {
    box-shadow: none;
  }

  @media (max-width: 960px) {
    .v-stepper {
      margin-top: 56px;

      &__header {
        height: 48px;
      }

      &__step {
        padding-top: 12px;
        padding-bottom: 12px;
      }

    }
  }
</style>
