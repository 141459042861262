<template>
  <v-container :class="{ 'fill-height': $vuetify.breakpoint.smAndUp }">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="d-flex justify-center align-center"
      >
        <p>404</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundComponent',
  created() {
    window.location.href = '/404.php';
  },
};
</script>
