<template>
  <v-container>
    <v-row
      class="fill-height align-center justify-center pl-sm-2 pr-sm-2 mt-lg-10"
    >
      <v-col
        v-for="(vehicle, index) in vehicles"
        :key="index"
        cols="12"
        sm="6"
        :md="(vehicles.length === 1) ? 6 : 4"
        lg="4"
      >
        <VehicleCard
          :vehicle="vehicle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import VehicleCard from '../components/VehicleCard.vue';

export default {
  name: 'Vehicle', // step-2
  components: {
    VehicleCard,
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    vehicles() {
      return this.$kyburz.vehicles.filter((vehicle) => vehicle.type === this.selectedType);
    },
    cardImageHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 300;
      }
      return 200;
    },
    sm() {
      if (this.vehicles.length === 3) {
        return 4;
      }
      return 6;
    },
    md() {
      if (this.vehicles.length === 3) {
        return 4;
      }
      return 3;
    },
  },
  created() {
    this.checkStepComplete();
  },
  mounted() {
    this.$track(this.$options.name, null, null, this.selectedType, null);
    this.$ga(this.$options.name, this.$router.currentRoute.path);
  },
  methods: {
    checkStepComplete() {
      if (
        this.selectedVehicle !== null
      ) {
        this.$store.commit('SET_STEP_COMPLETE', 2);
      }
    },
  },
};
</script>

<style lang="scss">
.vehicle-card {
  width: 100%;
  height: 100%;
  max-width: 500px;

  .v-input__slot {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 600px) {
  .vehicle-card {
    width: 300px;
    margin: 0 auto;
  }
}
</style>
