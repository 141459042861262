import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './filters';
import TrackingPlugin from './plugins/tracking';
import GoogleAnalyticsPlugin from './plugins/ga';
import KyburzPlugin from './plugins/kyburz';

const ConfigPlugin = require('./plugins/config');

if (process.env.NODE_ENV === 'production') {
  let sentryDsn = 'https://e7026ed45ea046479b261b0deedb8721@o374820.ingest.sentry.io/5670403';
  if (window.location.hostname === 'configurator.kyburz-switzerland.ch') {
    sentryDsn = 'https://f5dd2292e2504dca982f96b6f47f9717@o374820.ingest.sentry.io/5670409';
  }

  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

Vue.use(ConfigPlugin);
Vue.use(TrackingPlugin);
Vue.use(GoogleAnalyticsPlugin);
Vue.use(KyburzPlugin);

Vue.config.productionTip = false;

function getLastCompleteStep() {
  const completeSteps = store.state.steps.filter((step) => step.complete === true);
  if (completeSteps && completeSteps.length > 0) {
    return completeSteps[completeSteps.length - 1];
  }
  return null;
}

/**
 * prevent loading of a subpage, f.e. /configurator if previous, necessary selection have not been made
 * -> redirect to first incomplete step
 *
 * if a user comes with a url-parameter (type and/or lang), the store is reset and that page is shown
 * (see functions below, after vue initialization)
 */
router.beforeEach((to, from, next) => {
  if (from.name === null) { // direct page call
    if (to.meta.step === 1) { // direct call to homepage -> just show it
      store.commit('SET_CURRENT_STEP', to.meta.step);
      next();
    } else {
      const lastCompleteStep = getLastCompleteStep();
      if (lastCompleteStep === null) { // no step complete -> show step-1
        store.commit('SET_CURRENT_STEP', 1);
        next({ name: 'step-1' });
      } else if (lastCompleteStep.id + 1 < to.meta.step) { // previous step not complete -> show first incomplete step
        store.commit('SET_CURRENT_STEP', lastCompleteStep.id + 1);
        next({ name: `step-${lastCompleteStep.id + 1}` });
      } else {
        store.commit('SET_CURRENT_STEP', to.meta.step);
        next();
      }
    }
  } else { // navigated with vue-router -> just show it
    store.commit('SET_CURRENT_STEP', to.meta.step);
    next();
  }
});

// https://vuejs.org/v2/guide/mixins.html#Global-Mixin
Vue.mixin({
  methods: {
    mixinSendScrollUpCommandToParentPage() {
      if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage('scrollup');
      }
      return false;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
