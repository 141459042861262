import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { kyburz } from '../plugins/kyburz';

import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    currentStep: 1,
    lastStep: 7,
    steps: kyburz.steps,
    viewMode: 'standalone',
    order: {
      type: null,
      vehicle: null,
      version: null,
      color: null,
      colorRal: null,
      front: null,
      back: null,
      rim: null,
      windshield: null,
      wing: null,
      sidepanel: null,
      door: null,
      roof: null,
      foiling: null,
      accessories: {},
      delivery: 1,
      instruction: false,
      countVehicles: 1,
      previewImage: null,
    },
    person: {
      task: null, // order|offer
      gender: null,
      firstname: null,
      lastname: null,
      company: null,
      street: null,
      zip: null,
      city: null,
      country: null,
      email: null,
      phone: null,
      dateofbirth: null,
      message: null,
      agb: false,
      datenschutz: false,
      lang: 'de',
    },
  },
  mutations,
  getters,
});

/*

fot switch to cookies instead of localStorage

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

import { kyburz } from '../plugins/kyburz';

import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

let cookieOptions = {
  expires: 7,
  secure: true,
  domain: '.kyburz.local',
};

if (process.env.NODE_ENV === 'development') {
  cookieOptions = {
    expires: 7,
  };
}

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, cookieOptions),
      removeItem: key => Cookies.remove(key),
    },
  })],
  state: {
    currentStep: 1,
    lastStep: 7,
    steps: kyburz.steps,
    viewMode: 'standalone',
    sheet: {
      visible: false,
      text: '',
    },
  ...

*/
