import { kyburz } from '../plugins/kyburz';

export default {
  /* order total for display in footer */
  orderTotal: (state, getters) => {
    if (state.order.type !== null && state.order.vehicle !== null && state.order.version !== null) {
      let total = getters.vehicleTotal;

      if (state.order.instruction) {
        const instruction = kyburz.instructions.find((ins) => ins.value === state.order.instruction);
        if (instruction) {
          total += instruction.price;
        }
      }

      if (state.order.countVehicles > 1) {
        total *= state.order.countVehicles;
      }

      return total;
    }

    return 0;
  },
  vehicleTotal: (state) => {
    let total = 0;

    if (state.order.type !== null && state.order.vehicle !== null && state.order.version !== null) {
      const vehicle = kyburz.vehicles.find((veh) => veh.id === state.order.vehicle);
      if (vehicle) {
        const version = vehicle.versions.find((ver) => ver.id === state.order.version);
        if (version) {
          total += version.price;
        }
      }

      if (state.order.color !== null) {
        const color = kyburz.colors.find((col) => col.id === state.order.color);
        if (color) {
          total += color[state.order.vehicle].price;
        }
      }

      if (state.order.front !== null) {
        const front = kyburz.fronts.find((fro) => fro.id === state.order.front);
        if (front) {
          total += front[state.order.vehicle].price;
        }
      }

      if (state.order.back !== null) {
        const back = kyburz.backs.find((bac) => bac.id === state.order.back);
        if (back) {
          total += back[state.order.vehicle].price;
        }
      }

      if (state.order.rim !== null) {
        const rim = kyburz.rims.find((r) => r.id === state.order.rim);
        if (rim) {
          total += rim[state.order.vehicle].price;
        }
      }

      if (state.order.windshield !== null) {
        const windshield = kyburz.windshields.find((r) => r.id === state.order.windshield);
        if (windshield) {
          total += windshield[state.order.vehicle].price;
        }
      }

      if (state.order.wing !== null) {
        const wing = kyburz.wings.find((r) => r.id === state.order.wing);
        if (wing) {
          total += wing[state.order.vehicle].price;
        }
      }

      if (state.order.sidepanel !== null) {
        const sidepanel = kyburz.sidepanels.find((r) => r.id === state.order.sidepanel);
        if (sidepanel) {
          total += sidepanel[state.order.vehicle].price;
        }
      }

      if (state.order.foiling !== null) {
        const foiling = kyburz.foilings.find((r) => r.id === state.order.foiling);
        if (foiling) {
          total += foiling[state.order.vehicle].price;
        }
      }

      if (state.order.door !== null) {
        const door = kyburz.doors.find((r) => r.id === state.order.door);
        if (door) {
          total += door[state.order.vehicle].price;
        }
      }

      if (state.order.roof !== null) {
        const roof = kyburz.roofs.find((r) => r.id === state.order.roof);
        if (roof) {
          total += roof[state.order.vehicle].price;
        }
      }

      // accessories
      Object.keys(state.order.accessories).forEach((element) => {
        let accessory = null;
        if (element === 'battery') {
          accessory = kyburz.accessories.batteries.find((item) => item.value === state.order.accessories.battery);
        } else if (element === 'bigBattery') {
          accessory = kyburz.accessories.bigBatteries.find((item) => item.value === state.order.accessories.bigBattery);
        } else {
          accessory = kyburz.accessories[`${element}s`].find((item) => item.value === state.order.accessories[element]);
        }
        if (accessory) {
          total += accessory.price;
        }
      });

      const delivery = kyburz.deliveries.find((del) => del.value === state.order.delivery);
      if (delivery) {
        total += delivery.price;
      }
    }

    return total;
  },
  /* for database storing */
  orderData: (state, getters) => {
    const result = {};

    result.order = JSON.parse(JSON.stringify(state.order));

    result.order.vehicleTotal = 0;

    if (result.order.type !== null && result.order.vehicle !== null && result.order.version !== null) {
      const type = kyburz.vehicles.filter((veh) => veh.id === result.order.vehicle)[0];
      const version = type.versions.find((ver) => ver.id === result.order.version);
      result.order.vehicle = `${type.name[state.person.lang]} ${version.name[state.person.lang]}`;
      result.order.vehicleTotal += version.price;

      if (result.order.color !== null) {
        const color = kyburz.colors.find((col) => col.id === result.order.color);
        result.order.color = color.name[state.person.lang];
        result.order.vehicleTotal += color[state.order.vehicle].price;
      }

      if (result.order.front !== null) {
        const front = kyburz.fronts.find((fro) => fro.id === result.order.front);
        result.order.front = front.name[state.person.lang];
        result.order.vehicleTotal += front[state.order.vehicle].price;
      }

      if (result.order.back !== null) {
        const back = kyburz.backs.find((bac) => bac.id === result.order.back);
        result.order.back = back.name[state.person.lang];
        result.order.vehicleTotal += back[state.order.vehicle].price;
      }

      if (result.order.rim !== null) {
        const rim = kyburz.rims.find((r) => r.id === result.order.rim);
        result.order.rim = rim.name[state.person.lang];
        result.order.vehicleTotal += rim[state.order.vehicle].price;
      }

      if (result.order.windshield !== null) {
        const windshield = kyburz.windshields.find((r) => r.id === result.order.windshield);
        result.order.windshield = windshield.name[state.person.lang];
        result.order.vehicleTotal += windshield[state.order.vehicle].price;
      }

      if (result.order.wing !== null) {
        const wing = kyburz.wings.find((r) => r.id === result.order.wing);
        result.order.wing = wing.name[state.person.lang];
        result.order.vehicleTotal += wing[state.order.vehicle].price;
      }

      if (result.order.sidepanel !== null) {
        const sidepanel = kyburz.sidepanels.find((r) => r.id === result.order.sidepanel);
        result.order.sidepanel = sidepanel.name[state.person.lang];
        result.order.vehicleTotal += sidepanel[state.order.vehicle].price;
      }

      if (result.order.foiling !== null) {
        const foiling = kyburz.foilings.find((r) => r.id === result.order.foiling);
        result.order.foiling = foiling.name[state.person.lang];
        result.order.vehicleTotal += foiling[state.order.vehicle].price;
      }

      if (result.order.door !== null) {
        const door = kyburz.doors.find((r) => r.id === result.order.door);
        result.order.door = door.name[state.person.lang];
        result.order.vehicleTotal += door[state.order.vehicle].price;
      }

      if (result.order.roof !== null) {
        const roof = kyburz.roofs.find((r) => r.id === result.order.roof);
        result.order.roof = roof.name[state.person.lang];
        result.order.vehicleTotal += roof[state.order.vehicle].price;
      }

      // accessories
      const allAccessories = getters.getAllAccessories;
      allAccessories.forEach((element) => {
        if (typeof result.order.accessories[element] !== 'undefined') {
          let accessory = null;
          if (element === 'battery') {
            accessory = kyburz.accessories.batteries.find((item) => item.value === result.order.accessories.battery);
          } else if (element === 'bigBattery') {
            accessory = kyburz.accessories.bigBatteries.find((item) => item.value === result.order.accessories.bigBattery);
          } else {
            accessory = kyburz.accessories[`${element}s`].find((item) => item.value === result.order.accessories[element]);
          }
          result.order[element] = accessory.name[state.person.lang];
          result.order.vehicleTotal += accessory.price;
        } else {
          result.order[element] = null;
        }
      });

      if (result.order.delivery !== null) {
        const delivery = kyburz.deliveries.find((del) => del.value === result.order.delivery);
        result.order.delivery = delivery.name[state.person.lang];
        result.order.vehicleTotal += delivery.price;
      }

      if (result.order.instruction !== null) {
        result.order.instruction = kyburz.instructions.find((ins) => ins.value === result.order.instruction).name[state.person.lang];
      }

      result.order.total = getters.orderTotal;
    }

    delete result.order.accessories;

    result.person = JSON.parse(JSON.stringify(state.person));

    return result;
  },
  /* mail content, translated */
  orderContent: (state, getters) => {
    const result = {};

    result.order = JSON.parse(JSON.stringify(state.order));

    result.order.vehicleTotal = 0;

    if (result.order.type !== null && result.order.vehicle !== null && result.order.version !== null) {
      const type = kyburz.vehicles.filter((veh) => veh.id === result.order.vehicle)[0];
      const version = type.versions.find((ver) => ver.id === result.order.version);
      const name = `${type.name[state.person.lang]} ${version.name[state.person.lang]}`;

      result.order.vehicle = {
        name,
        price: version.price,
        artNr: version.artNr,
      };
      result.order.vehicleTotal += version.price;

      if (result.order.color !== null) {
        const color = kyburz.colors.find((col) => col.id === result.order.color);
        result.order.color = {
          name: color.name[state.person.lang],
          price: color[state.order.vehicle].price,
          artNr: color[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += color[state.order.vehicle].price;
      }

      if (result.order.front !== null) {
        const front = kyburz.fronts.find((fro) => fro.id === result.order.front);
        result.order.front = {
          name: front.name[state.person.lang],
          price: front[state.order.vehicle].price,
          artNr: front[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += front[state.order.vehicle].price;
      }

      if (result.order.back !== null) {
        const back = kyburz.backs.find((bac) => bac.id === result.order.back);
        result.order.back = {
          name: back.name[state.person.lang],
          price: back[state.order.vehicle].price,
          artNr: back[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += back[state.order.vehicle].price;
      }

      if (result.order.rim !== null) {
        const rim = kyburz.rims.find((r) => r.id === result.order.rim);
        result.order.rim = {
          name: rim.name[state.person.lang],
          price: rim[state.order.vehicle].price,
          artNr: rim[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += rim[state.order.vehicle].price;
      }

      if (result.order.windshield !== null) {
        const windshield = kyburz.windshields.find((bac) => bac.id === result.order.windshield);
        result.order.windshield = {
          name: windshield.name[state.person.lang],
          price: windshield[state.order.vehicle].price,
          artNr: windshield[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += windshield[state.order.vehicle].price;
      }

      if (result.order.wing !== null) {
        const wing = kyburz.wings.find((bac) => bac.id === result.order.wing);
        result.order.wing = {
          name: wing.name[state.person.lang],
          price: wing[state.order.vehicle].price,
          artNr: wing[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += wing[state.order.vehicle].price;
      }

      if (result.order.sidepanel !== null) {
        const sidepanel = kyburz.sidepanels.find((bac) => bac.id === result.order.sidepanel);
        result.order.sidepanel = {
          name: sidepanel.name[state.person.lang],
          price: sidepanel[state.order.vehicle].price,
          artNr: sidepanel[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += sidepanel[state.order.vehicle].price;
      }

      if (result.order.foiling !== null) {
        const foiling = kyburz.foilings.find((bac) => bac.id === result.order.foiling);
        result.order.foiling = {
          name: foiling.name[state.person.lang],
          price: foiling[state.order.vehicle].price,
          artNr: foiling[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += foiling[state.order.vehicle].price;
      }

      if (result.order.door !== null) {
        const door = kyburz.doors.find((bac) => bac.id === result.order.door);
        result.order.door = {
          name: door.name[state.person.lang],
          price: door[state.order.vehicle].price,
          artNr: door[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += door[state.order.vehicle].price;
      }

      if (result.order.roof !== null) {
        const roof = kyburz.roofs.find((bac) => bac.id === result.order.roof);
        result.order.roof = {
          name: roof.name[state.person.lang],
          price: roof[state.order.vehicle].price,
          artNr: roof[state.order.vehicle].artNr,
        };
        result.order.vehicleTotal += roof[state.order.vehicle].price;
      }

      // accessories
      Object.keys(state.order.accessories).forEach((element) => {
        let accessory = null;
        if (element === 'battery') {
          accessory = kyburz.accessories.batteries.find((item) => item.value === result.order.accessories.battery);
        } else if (element === 'bigBattery') {
          accessory = kyburz.accessories.bigBatteries.find((item) => item.value === result.order.accessories.bigBattery);
        } else {
          accessory = kyburz.accessories[`${element}s`].find((item) => item.value === result.order.accessories[element]);
        }
        result.order.accessories[element] = {
          name: accessory.name[state.person.lang],
          price: accessory.price,
          artNr: accessory.artNr,
        };
        result.order.vehicleTotal += accessory.price;
      });

      if (result.order.delivery !== null) {
        const delivery = kyburz.deliveries.find((del) => del.value === result.order.delivery);
        result.order.delivery = delivery.name[state.person.lang];
        result.order.delivery = {
          name: delivery.name[state.person.lang],
          price: delivery.price,
        };
        result.order.vehicleTotal += delivery.price;
      }

      if (result.order.instruction !== null) {
        const instruction = kyburz.instructions.find((ins) => ins.value === result.order.instruction);
        result.order.instruction = {
          name: instruction.name[state.person.lang],
          price: instruction.price,
        };
      }
    }

    result.order.total = getters.orderTotal;

    result.person = JSON.parse(JSON.stringify(state.person));

    return result;
  },
  getAllAccessories: () => {
    const allAccessories = [];

    kyburz.types.forEach((type) => {
      allAccessories.push(...Object.keys(type.defaultOrder.accessories));
    });

    const unique = (value, index, self) => self.indexOf(value) === index;
    const uniqueAccessories = allAccessories.filter(unique);

    return uniqueAccessories;
  },
};
