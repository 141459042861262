<template>
  <div
    v-if="previousButtonDisabled !== true || nextButtonDisabled !== true"
    class="footer elevation-1"
  >
    <v-row>
      <v-col
        cols="12"
        sm="5"
        class="d-flex justify-center align-center justify-sm-end"
      >
        <v-btn
          color="primary"
          class="footer__button footer__button--previous"
          tile
          :small="$vuetify.breakpoint.smAndDown"
          :medium="$vuetify.breakpoint.mdAndUp"
          :disabled="previousButtonDisabled"
          :aria-label="$t('gui.previous')"
          @click="goPreviousStep()"
        >
          <v-icon left>
            mdi-play
          </v-icon>
          {{ $t('gui.previous') }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="2"
        class="d-flex justify-center align-center pt-0 pb-0"
      >
        <p
          v-if="total > 0"
          class="footer__total text-center mb-0"
        >
          <strong>{{ total | toCurrency }}</strong>
          <span>{{ $t('gui.total-price') }}</span>
        </p>

        <v-alert
          class="alert-message"
          type="error"
          :class="{ 'visible': showAlert }"
          dense
          elevation="7"
        >
          {{ $t('gui.please-select') }}
        </v-alert>
      </v-col>

      <v-col
        v-if="currentStep < 6"
        cols="12"
        sm="5"
        class="d-flex justify-center align-center justify-sm-start"
      >
        <v-btn
          color="primary"
          class="footer__button footer__button--next"
          tile
          :small="$vuetify.breakpoint.smAndDown"
          :medium="$vuetify.breakpoint.mdAndUp"
          :disabled="nextButtonDisabled"
          :aria-label="$t('gui.next')"
          @click="goNextStep()"
        >
          {{ $t('gui.next') }}
          <v-icon right>
            mdi-play
          </v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-else-if="currentStep === 6"
        cols="12"
        sm="4"
        class="d-flex flex-column flex-lg-row justify-center justify-lg-start align-center align-sm-start"
      >
        <v-progress-circular
          v-if="goToTerminationDisabled"
          :size="($vuetify.breakpoint.smAndUp) ? 40 : 28"
          color="#efefef"
          indeterminate
        />
        <v-btn
          v-if="!goToTerminationDisabled"
          color="primary"
          tile
          class="mb-1 mr-lg-2"
          :small="$vuetify.breakpoint.smAndDown"
          :medium="$vuetify.breakpoint.mdAndUp"
          :aria-label="$t('gui.next-offer')"
          :disabled="goToTerminationDisabled"
          @click="goNextStepOffer"
        >
          {{ $t('gui.next-offer') }}
        </v-btn>

        <v-btn
          v-if="!goToTerminationDisabled"
          color="primary"
          tile
          :small="$vuetify.breakpoint.smAndDown"
          :medium="$vuetify.breakpoint.mdAndUp"
          :aria-label="$t('gui.next-order')"
          :disabled="goToTerminationDisabled"
          @click="goNextStepOrder"
        >
          {{ $t('gui.next-order') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * this footer component is now used as a second header
 */
import { mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

export default {
  name: 'Footer',
  data: () => ({
    showAlert: false,
  }),
  computed: {
    ...mapState({
      currentStep: (state) => state.currentStep,
      lastStep: (state) => state.lastStep,
      steps: (state) => state.steps,
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    step() {
      return this.steps.find((st) => st.id === this.currentStep);
    },
    total() {
      return this.$store.getters.orderTotal;
    },
    previousButtonDisabled() {
      if (this.currentStep === 1) {
        return true;
      }
      return false;
    },
    nextButtonDisabled() {
      if (
        (this.step.complete === true || this.currentStep > 2)
        || (this.currentStep === 2 && this.selectedType === 'eRod')
      ) {
        return false;
      }
      return true;
    },
    goToTerminationDisabled() {
      if (this.step.complete === false && this.currentStep === 6) {
        return true;
      }
      return false;
    },
  },
  methods: {
    goNextStep() {
      this.setShowAlert(false);
      if (this.step.id === 1 && this.nextButtonDisabled) {
        this.setShowAlert(true);
      } else {
        this.setShowAlert(false);
        if (this.currentStep > 2) {
          this.$store.commit('SET_STEP_COMPLETE', this.currentStep);
        }
        this.$router.push({ name: `step-${this.currentStep + 1}` }).catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
        });
      }
    },
    goPreviousStep() {
      this.$router.push({ name: `step-${this.currentStep - 1}` }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    goNextStepOffer() {
      this.$track('Summary', 'choose', 'Offer', this.selectedType, this.selectedVehicle);
      this.$store.commit('SET_STEP_COMPLETE', this.currentStep);
      this.$store.commit('SET_PERSON_ORDER_TASK', 'offer');
      this.$router.push({ name: `step-${this.currentStep + 1}` }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    goNextStepOrder() {
      this.$track('Summary', 'choose', 'Order', this.selectedType, this.selectedVehicle);
      this.$store.commit('SET_STEP_COMPLETE', this.currentStep);
      this.$store.commit('SET_PERSON_ORDER_TASK', 'order');
      this.$router.push({ name: `step-${this.currentStep + 1}` }).catch((error) => {
        if (this.$isDev) {
          console.error(error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      });
    },
    setShowAlert(yesno) {
      if (yesno) {
        this.showAlert = true;

        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      } else {
        this.showAlert = false;
      }
    },
  },
};
</script>

<style lang="scss">
.footer {

  padding: 12px 24px;

  &__total {
  font-size: 16px;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    strong {
      font-weight: 600;
      display: inline;
      white-space: nowrap;
    }

    span {
      display: block;

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
  }

  &__button {

    &--previous {

      .v-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.v-alert.alert-message {
  display: none;
  margin: 0;
  margin-right: 2rem;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transform: scale(0);

  &.visible {
    display: block;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    transform: scale(1);
  }
}

.v-application .v-btn {
  transition: all .6s cubic-bezier(.15,1.05,.9,1);

  &:hover {
    background-color: #1b49a6;
    color: #fff !important;
    transition: all .6s cubic-bezier(.15,1.05,.9,1);
  }
}
</style>
