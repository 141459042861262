export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ga = (title, path) => {
      if (window.location.hostname === 'configurator.kyburz-switzerland.ch') {
        window.gtag('config', 'UA-4431771-19', {
          page_title: title,
          page_path: path,
        });
      }
    };
  },
};
