import Vue from 'vue';
import Router from 'vue-router';
import Type from '../views/Type.vue';

Vue.use(Router);

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'step-1',
      component: Type,
      alias: '/type',
      meta: {
        step: 1,
      },
    },
    {
      path: '/vehicle',
      name: 'step-2',
      component: () => import(/* webpackChunkName: "vehicle" */ '../views/Vehicle.vue'),
      meta: {
        step: 2,
      },
    },
    {
      path: '/configurator',
      name: 'step-3',
      component: () => import(/* webpackChunkName: "configurator" */ '../views/Configurator.vue'),
      meta: {
        step: 3,
      },
    },
    {
      path: '/accessories',
      name: 'step-4',
      component: () => import(/* webpackChunkName: "accessories" */ '../views/Accessories.vue'),
      meta: {
        step: 4,
      },
    },
    {
      path: '/delivery',
      name: 'step-5',
      component: () => import(/* webpackChunkName: "delivery" */ '../views/Delivery.vue'),
      meta: {
        step: 5,
      },
    },
    {
      path: '/summary',
      name: 'step-6',
      component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue'),
      meta: {
        step: 6,
      },
    },
    {
      path: '/termination',
      name: 'step-7',
      component: () => import(/* webpackChunkName: "termination" */ '../views/Termination.vue'),
      meta: {
        step: 7,
      },
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
    },
  ],
});
