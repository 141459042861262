<template>
  <v-app-bar
    app
    :elevation="($vuetify.breakpoint.xsOnly) ? 2 : 0"
  >
    <v-toolbar-title
      class="page-title d-flex align-center"
      @click="goHome"
    >
      <img
        src="/img/Logo-Kyburz.svg"
        alt="KYBURZ Logo"
        class="mr-4 logo"
      >
      <div>
        {{ $t('gui.header-title') }}
      </div>
    </v-toolbar-title>

    <v-spacer />

    <v-app-bar-nav-icon
      small
      aria-label="menu"
      class="d-none"
      @click.stop="toggleDrawer()"
    />

    <div class="lang-select">
      <v-select
        v-model="$i18n.locale"
        dense
        outlined
        :items="langs"
        hide-details
        :label="$t('form.lang')"
        @change="changeLangInLocalstorage()"
      />
    </div>
  </v-app-bar>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
  name: 'Header',
  data: () => ({
    langs: [
      {
        text: 'DE',
        value: 'de',
      },
      {
        text: 'FR',
        value: 'fr',
      },
      {
        text: 'EN',
        value: 'en',
      },
    ],
  }),
  methods: {
    changeLangInLocalstorage() {
      localStorage.setItem('kyburz-configurator-lang', this.$i18n.locale);
      this.$store.commit('SET_PERSON_LANG', this.$i18n.locale);
    },
    goHome() {
      if (this.$router.currentRoute.name !== 'step-1') {
        this.$router.push({ name: 'step-1' }).catch((error) => {
          if (this.$isDev) {
            console.error(error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.page-title {
  font-size: 24px !important;
  font-weight: 700;
  letter-spacing: normal;
  color: #333;
  cursor: pointer;

  .logo {
    display: block;
    width: 130px;
    height: auto;

    @media (max-width: 320px) {
      width: 100px;
    }
  }

  & > div {
    position: relative;
    top: -1px;
  }
}

.lang-select {
  transform: scale(0.8);
  transform-origin: right center;
  width: 80px;
  text-transform: uppercase;

  .v-input__control {
    height: 40px;
  }

  .v-list-item__title {
    text-transform: uppercase;
  }
}

@media (max-width: 1264px) {
  .lang-select {
    transform: scale(0.7);
  }
}

@media (max-width: 600px) {
  .page-title {
    font-size: 16px !important;
  }
}

</style>
