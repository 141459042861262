/* eslint-disable */
// https://github.com/uuidjs/uuid
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  track(screen, action, value, type, vehicle) {
    const localStorageTracking = JSON.parse(localStorage.getItem('kyburz-configurator-tracking'));
    const oneDayBeforeNow = dayjs().subtract(1, 'day');

    let tracking = null;

    if (
      localStorageTracking === null // first time visit
      || (
        localStorageTracking !== null // returning visit, but more than 24 hours in the past -> new uuid, new timestamp
        && dayjs(localStorageTracking.timeStamp).isBefore(oneDayBeforeNow)
      )
    ) {
      // console.log('%c new visitor or last visist more than 24 hours in the past -> new uuid ', 'background-color: orangered; color: black;');
      tracking = {
        uuid: uuid(),
        timeStamp: dayjs(),
      };
      localStorage.setItem('kyburz-configurator-tracking', JSON.stringify(tracking));
    } else { // returning visitor within the last 24 hours -> only update the timestamp
      // console.log('%c continue session -> reuse uuid ', 'background-color: lightgreen; color: black;');
      tracking = {
        uuid: localStorageTracking.uuid,
        timeStamp: dayjs(),
      };
      localStorage.setItem('kyburz-configurator-tracking', JSON.stringify(tracking));
    }

    const data = {
      uuid: tracking.uuid,
      screen,
      action,
      value,
      type,
      vehicle,
    };
    // console.log('data', data);

    if (process.env.NODE_ENV === 'development') {
      console.log('tracking-data', data); // eslint-disable-line
    } else {
      axios({
        url: '/api/log',
        method: 'post',
        data,
      }).catch((error) => {
        // Error 😨
        // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
        if (error.response) {
          /*
              * The request was made and the server responded with a
              * status code that falls out of the range of 2xx
              */
          console.log(error.response.data); // eslint-disable-line
          console.log(error.response.status); // eslint-disable-line
          console.log(error.response.headers); // eslint-disable-line
        } else if (error.request) {
          /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
          console.log(error.request); // eslint-disable-line
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message); // eslint-disable-line
        }
        console.log(error.config); // eslint-disable-line
      });
    }
  },
};
