<template>
  <v-card
    class="card accessory-card"
  >
    <v-img
      v-if="accessory === 'cover' && selectedType === 'PLUS'"
      src="img/cover-plus.jpg"
      alt="Cover PLUS"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'naviPlugSocket' && selectedType === 'DXC'"
      src="img/naviplugsocket-DXC.jpg"
      alt="Cover PLUS"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'usbPlugSocket' && selectedType === 'PLUS_II'"
      src="img/USB_PLUS_II.jpg"
      alt="USB Plug Socket"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'usbPlugSocket' && selectedType === 'DXC'"
      src="img/usb_Dreiraeder.jpg"
      alt="USB Plug Socket"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'towCoupling' && selectedType === 'PLUS_II'"
      src="img/towcoupling-PLUSII.jpg"
      alt="Cover PLUS II"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'caneholder' && selectedVehicle === 'DXS502L'"
      src="img/caneholder_DXS502L.jpg"
      alt="Cane Holder DXS-2ndLife"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'caneholder' && selectedType === 'DXS-2ndLife'"
      src="img/caneholder_DXS402L.jpg"
      alt="Cane Holder DXS-2ndLife"
      aspect-ratio="1.5"
    />
    <v-img
      v-else-if="accessory === 'heatedGrip' && selectedType === 'DX2'"
      src="img/heatedgrip_dx2.jpg"
      alt="Heated Grip DX2"
      aspect-ratio="1.5"
    />
    <v-img
      v-else
      :src="`img/${accessory.toLowerCase()}.jpg`"
      :alt="accessory"
      aspect-ratio="1.5"
    />
    <v-card-title
      v-if="selectedType === 'DXS-2ndLife' && accessory === 'battery'"
      class="card__title card__title--accessory pb-0"
    >
      {{ $t(`content.${accessory}-new`) }}
    </v-card-title>
    <v-card-title
      v-else
      class="card__title card__title--accessory pb-0"
    >
      {{ $t(`content.${accessory}`) }}
    </v-card-title>
    <v-card-text
      :class="`card__text card__text--accessminheight-${selectedType} card__text--accessory`"
    >
      <!-- eslint-disable -->
      <p v-html="$t(`content.${accessory}-desc`)" />
      <!-- eslint-enable -->
      <v-radio-group
        v-model="accessorySelected"
        class=" pt-0 mt-0"
      >
        <v-radio
          v-for="(accessoryOption, index) in accessoryForVehicle"
          :key="index"
          :value="accessoryOption.value"
          color="primary"
        >
          <template v-slot:label>
            <!-- eslint-disable-next-line max-len vue/no-v-html -->
            <div v-html="`${accessoryOption.name[$i18n.locale]} ${$options.filters.toCurrencyRemark(accessoryOption.price)}` + (($debug && accessoryOption.artNr !== null) ? '&nbsp;<code>' + accessoryOption.artNr + '</code>' : '')" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccessoryCard', // component used in step-3
  props: {
    accessory: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      selectedType: (state) => state.order.type,
      selectedVehicle: (state) => state.order.vehicle,
    }),
    accessorySelected: {
      get() {
        return this.$store.state.order.accessories[this.accessory];
      },
      set(newValue) {
        const commit = `SELECT_${this.accessory.toUpperCase()}`;
        this.$store.commit(commit, newValue);
        this.$emit('optionChanged');
      },
    },
    accessoryForVehicle() {
      let accessoryOptions = [];
      if (this.accessory === 'battery') {
        accessoryOptions = this.$kyburz.accessories.batteries;
      } else if (this.accessory === 'bigBattery') {
        accessoryOptions = this.$kyburz.accessories.bigBatteries;
      } else {
        accessoryOptions = this.$kyburz.accessories[`${this.accessory}s`];
      }

      const accessories = accessoryOptions.filter((element) => {
        if (element.vehicles === 'all' || element.vehicles.includes(this.selectedVehicle)) {
          return true;
        }
        return false;
      });

      let result = null;

      // battery-data has property order
      if (this.accessory === 'battery' || this.accessory === 'bigBattery') {
        result = accessories.sort((a, b) => Number(a.order) - Number(b.order));
      } else {
        result = accessories;
      }

      return result;
    },
  },
};
</script>
